
// eslint-disable-next-line
const DeleteLineIcon = ({ color }) => {
    return (
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6665 4.08341V11.6667C11.6665 12.3111 11.1442 12.8334 10.4998 12.8334H3.49984C2.8555 12.8334 2.33317 12.3111 2.33317 11.6667V4.08341H1.1665V2.91675H12.8332V4.08341H11.6665ZM3.49984 4.08341V11.6667H10.4998V4.08341H3.49984ZM6.4165 5.25008H7.58317V6.41675H6.4165V5.25008ZM6.4165 7.00008H7.58317V8.16675H6.4165V7.00008ZM6.4165 8.75008H7.58317V9.91675H6.4165V8.75008ZM4.08317 1.16675H9.9165V2.33341H4.08317V1.16675Z" fill={color} />
        </svg>
    )
}

export default DeleteLineIcon