import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Chip } from '@mui/material';
import Downshift from "downshift";
import InputBase from '@mui/material/InputBase';
import { alpha } from '@mui/material/styles';

export default function TagsInput({ ...props }) {
    const { selectedTags, placeholder, tags, actionType, value, ...other } = props;
    const [inputValue, setInputValue] = React.useState("");
    const [selectedItem, setSelectedItem] = React.useState([]);

    useEffect(() => {
        setSelectedItem(tags);
    }, [tags]);

    useEffect(() => {
        selectedTags(selectedItem, actionType);

        // eslint-disable-next-line
    }, [selectedItem]);

    function handleKeyDown(event, onBlur) {
        if (/* event.key === "Enter"  */["Enter", "Tab", ",", " "].includes(event.key) || onBlur) {
            const newSelectedItem = [...selectedItem];
            const duplicatedValues = newSelectedItem.indexOf(
                event.target.value.trim()
            );

            if (duplicatedValues !== -1) {
                setInputValue("");
                return;
            }
            if (!event.target.value.replace(/\s/g, "").length) return;

            newSelectedItem.push(event.target.value.trim());
            setSelectedItem(newSelectedItem);
            setInputValue("");
        }
        if (
            selectedItem.length &&
            !inputValue.length &&
            event.key === "Backspace"
        ) {
            setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
        }
    }

    function handleChange(item) {
        let newSelectedItem = [...selectedItem];
        if (newSelectedItem.indexOf(item) === -1) {
            newSelectedItem = [...newSelectedItem, item];
        }
        setInputValue("");
        setSelectedItem(newSelectedItem);
    }

    const handleDelete = item => () => {
        const newSelectedItem = [...selectedItem];
        newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
        setSelectedItem(newSelectedItem);
    };

    function handleInputChange(event) {
        setInputValue(event.target.value);
    }

    useEffect(() => {
        if (value?.length) {
            setSelectedItem(value)
        }
        //eslint-disable-next-line
    }, [])

    return (
        <React.Fragment>
            <Downshift
                id="downshift-multiple"
                inputValue={inputValue}
                onChange={handleChange}
                selectedItem={selectedItem}
            >
                {({ getInputProps }) => {
                    //eslint-disable-next-line
                    const { onBlur, onChange, onFocus, onKeyDown,...inputProps } = getInputProps({
                        onKeyDown: handleKeyDown,
                        placeholder
                    });
                    return (
                        <div>
                            <InputBase
                                // placeholder="To"
                                // value={value}
                                // onChange={(e) => setTo(e.target.value)}
                                startAdornment={selectedItem.map(item => (
                                    <Chip
                                        key={item}
                                        tabIndex={-1}
                                        label={item}
                                        // className={classes.chip}
                                        onDelete={handleDelete(item)}
                                        variant="soft"
                                    />
                                ))}
                                onBlur={(e) => e.target.value.trim() ? handleKeyDown(e, true) : ""}
                                onKeyDown={(e) => handleKeyDown(e)}
                                onChange={event => {
                                    handleInputChange(event);
                                    onChange(event);
                                }}
                                // onFocus
                                sx={{
                                    px: 2,
                                    height: 48,
                                    width: '100%',
                                    borderBottom: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
                                    color: 'grey.700'
                                }}
                                {...other}
                                {...inputProps}
                            />
                        </div>
                    );
                }}
            </Downshift>
        </React.Fragment >
    );
}
TagsInput.defaultProps = {
    tags: []
};
TagsInput.propTypes = {
    selectedTags: PropTypes.func.isRequired,
    actionType: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.array,
    tags: PropTypes.arrayOf(PropTypes.string)
};
