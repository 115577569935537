
// eslint-disable-next-line
const CornerUpLeftLineIcon = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.0002 10.0003L19.0004 19.0002H17.0004L17.0003 12.0003L6.82845 12.0002L10.7782 15.9499L9.36396 17.3642L3 11.0002L9.36396 4.63623L10.7782 6.05045L6.8284 10.0002L19.0002 10.0003Z" fill={color} />
        </svg>
    )
}

export default CornerUpLeftLineIcon;