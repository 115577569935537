import React, { useEffect, useRef, useState, useContext } from 'react';
import { IconButton, styled, Typography, Box, Button, InputAdornment, TextField, CircularProgress, FormControl,InputLabel,Select,MenuItem } from "@mui/material";
import VerifiedIcon from '@mui/icons-material/Verified';
import CustomModal from 'src/components/modal/customModal';
import EditIcon from '@mui/icons-material/Edit';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { axiosPostCall } from 'src/services/apisCall';
import Logger from 'src/lib/Logger';
import { renderErrorMessage, renderSuccessMessage } from 'src/lib/toastMessage';
import { ToastContainer } from 'react-toastify';
import AvatarModal from './AvatarModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TokenContext } from '.././../context/TokenContext';

const MainSection = styled('div')(() => ({
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: "#E7E8E8",
    padding: '40px',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'row',
    gap: '14px'
}));

const ProfileSection = styled(IconButton)(() => ({
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    overflow: 'hidden',
}));

const DetailsSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '8px'
}));

const NameSection = styled(Typography)(() => ({
    fontSize: '22px',
    fontWeight: 500,
}));

const EmailSection = styled(Typography)(() => ({
    fontSize: '18px',
    fontWeight: 400,
    color: '#5B5C5D'
}));

const MobileSection = styled(Typography)(() => ({
    fontSize: '18px',
    fontWeight: 400,
    color: '#5B5C5D'
}));

const ProfileSectionModal = styled(IconButton)(() => ({
    position: 'relative',
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    overflow: 'hidden',
    '&:hover .upload-icon': {
        opacity: 5
    }
}));

const ProfileImage = styled('img')(() => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%',
}));

const UploadIconWrapper = styled(PhotoCameraIcon)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    '&:hover': {
        cursor: 'pointer'
    }
}));

const BasicSelect = styled(Select)(({ theme }) => ({
    borderRadius: '6px',
    '& input': {
        color: "#5B5C5D",
    },
    width: '350px',
}));

const EditProfileModal = ({ open, close, mobile, url, name, setToken, group }) => {
    const logger = new Logger();
    const [profileUrl, setProfileUrl] = useState(url)
    const [phone, setPhone] = useState(mobile);
    const [userName, setUserName] = useState(name)
    const [groupName, setGroupName] = useState(group)
    const [file, setFile] = useState(url);
    const [edit, setEdit] = useState(false);
    const mobileRef = useRef(null);
    const fileRef = useRef(null);
    const nameRef = useRef(null);
    const groupRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const handleSubmit = async () => {
        try {
            setLoading(true)
            const formData = new FormData;

            if (file) formData.append("profilePhoto", file);

            if (phone) formData.append("phone", phone);
            if (userName) formData.append("userName", userName);
            if (groupName) formData.append("group", groupName);

            const { message, token } = await axiosPostCall("updateProfile", formData);
            if (token) {
                setToken(token)
                setLoading(false)
            } else {
                setLoading(false)
                console.error("No token received from the API");
            }

            renderSuccessMessage(message)
            setTimeout(() => {
                close();
                navigate("/profile")
                window.location.reload(false);
            }, 500)

        } catch (error) {
            logger.error("details.jsx ,handleSubmit ,updateProfile", error);
            renderErrorMessage(error.message)
            setLoading(false)
        }
    }


    const fileChange = (file) => {
        setFile(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileUrl(reader.result)
            };
            reader.readAsDataURL(file);
        }
        else {
            setProfileUrl(url);
        }
    }

    useEffect(() => {
        setPhone(mobile)
    }, [mobile])

    useEffect(() => {
        setUserName(name)
    }, [name])

    useEffect(() => {
        setProfileUrl(url)
    }, [url])

    useEffect(() => {
        setGroupName(group)
    }, [group])

    const handleGroupSelect = (event) => {
        setGroupName(event.target.value)
    }


    const description = (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <ProfileSectionModal disableFocusRipple disableTouchRipple disableRipple onClick={() => fileRef.current.click()}>
                    <ProfileImage src={profileUrl} alt='profileImage' />
                    <UploadIconWrapper className="upload-icon" />
                    <input
                        type="file"
                        ref={fileRef}
                        accept='image/*'
                        onChange={(e) => fileChange(e.target.files[0])}
                        hidden
                    />
                </ProfileSectionModal>
            </Box>
            <TextField
                fullWidth
                label="Name"
                name="name"
                inputRef={nameRef}
                disabled={!edit}
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setEdit(true)} edge="end">
                                <EditIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                fullWidth
                label="Mobile"
                name="mobile"
                inputRef={mobileRef}
                disabled={!edit}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setEdit(true)} edge="end">
                                <EditIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <FormControl size="small" >
                <InputLabel id="select-label">Select user Group</InputLabel>
                <BasicSelect
                    labelId="select-label"
                    id="select=group"
                    value={groupName}
                    label="Select user Group"
                    onChange={handleGroupSelect}
                    inputRef={groupRef}
                >
                    <MenuItem value={"sales"}>Sales</MenuItem>
                    <MenuItem value={"support"}>Support</MenuItem>
                    <MenuItem value={"development"}>Development</MenuItem>
                </BasicSelect>
            </FormControl>

            <Button variant="contained" disabled={(!phone || !userName)} onClick={() => handleSubmit()}>
                {loading ? <><CircularProgress size={17} sx={{ color: "inherite", marginRight: '8px' }} /> Saving... </> : 'Save'}
            </Button>
        </>
    )

    return (
        <CustomModal open={open} title={"Edit Profile"} description={description} close={close} />
    )
}

const Details = () => {
    const [url, setUrl] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("")
    const [userGroup, setUserGroup] = useState("")

    const [openModal, setOpenModal] = useState(false);
    const [openAvatar, setOpenAvatar] = useState(false);
    const [searchParams] = useSearchParams();
    const { decodedToken, setToken } = useContext(TokenContext);
    const [loadingDetails, setLoadingDetails] = useState(true)

    useEffect(() => {
        setLoadingDetails(true)
        setTimeout(() => {
            if (decodedToken) {
                try {
                    const { profileUrl = "", userName = "", email = "", phone = "", group = "" } = decodedToken;
                    setUrl(profileUrl);
                    setName(userName);
                    setEmail(email);
                    setMobile(phone);
                    setLoadingDetails(false)
                    setUserGroup(group)
                } catch (error) {
                    console.error("Failed to decode token:", error);
                    setLoadingDetails(false)
                }
            } else {
                console.error("Token is not available or invalid.");
            }
        }, 400);
    }, [decodedToken]);

    useEffect(() => {

        const editPhone = searchParams.get("editPhone");
        if (editPhone) {
            setOpenModal(true)
            renderErrorMessage("Update name, mobile and group.")
        }
    },[])

    return (
        <MainSection>
            <ToastContainer />
            <ProfileSection disableFocusRipple disableTouchRipple disableRipple onClick={() => setOpenAvatar(true)}>
                <ProfileImage src={url} alt='profileImage' />
            </ProfileSection>
            <AvatarModal open={openAvatar} close={() => setOpenAvatar(false)} url={url} />
            {loadingDetails ? <><DetailsSection > <NameSection sx={{ fontWeight: '400', color: 'gray' }}>
                {"Loading..."}
            </NameSection>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                }}>

                    <EmailSection sx={{ fontWeight: '400', color: 'gray' }}>
                        {"Loading..."}
                    </EmailSection>

                    <VerifiedIcon sx={{ color: 'gray', fontWeight: '300', height: "18px" }} />
                </Box>
                <MobileSection sx={{ fontWeight: '400', color: 'gray' }}>
                    {"Loading..."}
                </MobileSection>
                <Button disabled variant="contained" onClick={() => setOpenModal(true)} >
                    Update Profile
                </Button>
            </DetailsSection> </> : <DetailsSection>
                <NameSection>
                    {name}
                </NameSection>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                }}>

                    <EmailSection>
                        {email}
                    </EmailSection>

                    <VerifiedIcon sx={{ color: '#4BB543' }} />
                </Box>
                <MobileSection>
                    {mobile}
                </MobileSection>
                <Button variant="contained" onClick={() => setOpenModal(true)} >
                    Update Profile
                </Button>

            </DetailsSection>}
            <EditProfileModal open={openModal} close={() => setOpenModal(false)} mobile={mobile} url={url} name={name} setToken={setToken} group={userGroup} />
        </MainSection>
    )
}

export default Details;