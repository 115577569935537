import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, MenuItem, Grid, Button, CircularProgress } from "@mui/material";

const PaymentForm = ({ onSubmit, initialValues = {} }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      amount: initialValues.amount || "",
      currency: initialValues.currency || "",
      paymentMethod: initialValues.paymentMethod || "",
      paymentStatus: initialValues.paymentStatus || "",
      transactionId: initialValues.transactionId || "",
      gatewayResponse: initialValues.gatewayResponse || "",
      createdAt: initialValues.createdAt || "",
      updatedAt: initialValues.updatedAt || "",
    },
    validationSchema: Yup.object({
      amount: Yup.number().required("Amount is required").min(0, "Amount must be positive"),
      currency: Yup.string().required("Currency is required"),
      paymentMethod: Yup.string().required("Payment Method is required"),
      paymentStatus: Yup.string().required("Payment Status is required"),
      transactionId: Yup.string().required("Transaction ID is required"),
    }),
    onSubmit: async () => {
      setLoading(true);
      try {
        await onSubmit(formik.values);
        formik.resetForm();
      } finally {
        setLoading(false);
      }
    },
  });

  const handleClick = () => {
    if (formik.isValid) {
      formik.handleSubmit();
    } else {
      formik.setTouched({
        amount: true,
        currency: true,
        paymentMethod: true,
        paymentStatus: true,
        transactionId: true,
      });
    }
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Amount *"
            name="amount"
            type="number"
            value={formik.values.amount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            label="Currency *"
            name="currency"
            value={formik.values.currency}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.currency && Boolean(formik.errors.currency)}
            helperText={formik.touched.currency && formik.errors.currency}
          >
            {["INR"].map((currency) => (
              <MenuItem key={currency} value={currency}>
                {currency}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            label="Payment Method *"
            name="paymentMethod"
            value={formik.values.paymentMethod}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.paymentMethod && Boolean(formik.errors.paymentMethod)}
            helperText={formik.touched.paymentMethod && formik.errors.paymentMethod}
          >
            {["Credit Card", "Debit Card", "PayPal", "Bank Transfer/Cheque", "other"].map(
              (method) => (
                <MenuItem key={method} value={method}>
                  {method}
                </MenuItem>
              )
            )}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            label="Payment Status *"
            name="paymentStatus"
            value={formik.values.paymentStatus}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.paymentStatus && Boolean(formik.errors.paymentStatus)}
            helperText={formik.touched.paymentStatus && formik.errors.paymentStatus}
          >
            {["Completed", "Failed", "Partial", "Refunded"].map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Transaction ID"
            name="transactionId"
            value={formik.values.transactionId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Message"
            name="gatewayResponse"
            value={formik.values.gatewayResponse}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleClick}
            disabled={loading}
            startIcon={loading && <CircularProgress size={18} />}
          >
            {loading ? "Updating..." : "Update"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PaymentForm;
