import { useState, useCallback, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
// import { WS_EVENTS } from 'src/config/Enums';
// import { wsEmit } from './ws';
// import { WsContext } from 'src/context/wsContext';

import { Tooltip, Menu, SvgIcon } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Editor from 'src/components/editor';
import Iconify from 'src/components/iconify';
import TagsInput from "./email-input";
import { Card, Typography } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import { LoadingButton as Button } from '@mui/lab';
import ComposeAttachments from './components/compose-attachments';
// import { renderErrorMessage } from 'src/lib/toast-message';
import EmojiMenu from './components/emoji-menu';
import _ from 'lodash';
// import SignatureMoreMenu from './components/signature-more-menu';
import Scrollbar from 'src/components/scrollbar';
import DeleteLineIcon from 'src/assets/svgs/icons/delete-line';
import SendPlane2LineIcon from 'src/assets/svgs/icons/send-plane-2-line';
import Attachment45LineIcon from 'src/assets/svgs/icons/attachment-45-line';
import EmotionLineIcon from 'src/assets/svgs/icons/emotion-line';
// import { CACHE_LABELS } from 'src/config/Enums';
// import {
//     updateCompleteRecordINIdb,
//     fetchCompleteRecordINIdb,
//   } from 'src/lib/index-db';
const MailReply = ({ mail, sendReply, isReplyToAll, rootId, isLast, isReply, drafts, selectedLabelId, maildata, deleteDraft }) => {
    const [message, setMessage] = useState('');
    // const { socket, userId, platform, conn, setupLocalList, setMailList, mails } = useContext(WsContext) || {};

    const [to, setTo] = useState();
    const [cc, setCc] = useState('');
    const [bcc, setBcc] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [signature, setSignature] = useState('');
    const [allSignatures, setAllSignatures] = useState([]);
    const [isSaved, setIsSaved] = useState(false)
    // const [currentId, setCurrentId] = useState("");

    const [draftData, setDraftData] = useState({});

    const [renderCC, setRenderCC] = useState(false);
    const [renderBCC, setRenderBCC] = useState(false);
    const [attachments, setAttachments] = useState([]);

    const [anchorElMore, setAnchorElMore] = useState(null);
    const openSearchFilter = Boolean(anchorElMore);
    const handleClickMore = (event) => {
        setAnchorElMore(event.currentTarget);
    };
    const handleCloseMore = () => {
        setAnchorElMore(null);
    };

    const [anchorElEmoji, setAnchorElEmoji] = useState(null);
    const openEmoji = Boolean(anchorElEmoji);
    const handleClickEmoji = (event) => {
        setAnchorElEmoji(event.currentTarget);
    };
    const handleCloseEmoji = () => {
        setAnchorElEmoji(null);
    };

    const fileInput = useRef();

    const validateFiles = (newFiles) => {
        const disallowedExtensions = ['.js', '.xcss', '.sh', '.bat'];
        const maxTotalSize = 25 * 1024 * 1024; // 25 MB in bytes

        // Combine existing files with the new ones
        const allFiles = [...attachments, ...newFiles];

        // Calculate the total size
        const totalSize = allFiles.reduce((acc, file) => acc + file.size, 0);

        // Check for disallowed extensions
        const hasDisallowedExtension = allFiles.some(file =>
            disallowedExtensions.some(ext => file.name.endsWith(ext))
        );

        // Validate size and extensions
        if (totalSize > maxTotalSize) {
            renderErrorMessage('File size exceeds the 25 MB limit.');
            return false;
        }

        if (hasDisallowedExtension) {
            renderErrorMessage('This file type is not allowed.');
            return false;
        }

        return true;
    };

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);

        if (validateFiles(newFiles)) {
            // If valid, update the state
            setAttachments(prevFiles => [...prevFiles, ...newFiles]);
        }

        event.target.value = '';
    };

    const removeAttachment = (id) => {
        const filterAttachments = attachments.filter((_, i) => i !== id);
        setAttachments(filterAttachments);
    }

    function handleSelecetedTags(emails, type) {
        const updatedEmails = emails.map(item => { return { name: "", address: item } });
        if (type == 'to') return setTo(updatedEmails);
        if (type == 'cc') return setCc(updatedEmails);
        if (type == 'bcc') return setBcc(updatedEmails);
    }

    const handleChangeMessage = useCallback((value) => {
        setMessage(value);
    }, []);

    const handleSend = async () => {
        try {
            setBtnLoader(true)

            const filesArray = [];
            const readFiles = Array.from(attachments).map((file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        filesArray.push({
                            fileName: file.name,
                            fileData: reader.result,
                            mimeType: file.type,
                        });
                        resolve();
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            });

            Promise.all(readFiles).then(async () => {
                const subData = mail?.subject;
                const checkRe = subData?.includes("Re:");
                const subject = checkRe ? subData : "Re: " + subData;

                let isDraft = false;
                let oldDraftData = {};

                if (Object.keys(draftData).length) {
                    isDraft = true;
                    oldDraftData = { thridId: mail.thridId, uid: draftData?.attributes.uid, messageId: draftData?.messageId }
                }
                setBtnLoader(true);
                await sendReply({ messageId: mail?.messageId, message, to, subject, cc, bcc, references: mail?.references, uid: mail?.attributes?.uid, rootId, isReplyToAll, filesArray, isDraft, oldDraftData }, setBtnLoader);
                setBtnLoader(false)
                isReply.onFalse();
            });


        } catch (error) {
            console.log(error)
        } finally {
            setBtnLoader(false)
        }
    }

    const handleEmojiChange = (unified) => {
        const sliceTextfirst = message.slice(0, -4);
        const sliceTextlast = message.slice(-4);

        const matchArr = ["</p>", '</div>', "</h1/>", "</h2/>", "</h3/>", "</h4/>", "</h5/>", "</h6/>"]
        if (matchArr.includes(sliceTextlast)) {
            const text = sliceTextfirst + unified + sliceTextlast;
            setMessage(text)
            return

        }

        const text = message + unified;
        setMessage(text)
    }

    console.log(mail)

    return (
        <Card sx={{
            overflow: 'visible',
            m: '10px',
            borderRadius: '3px',
            border: '1px solid',
            borderColor: 'grey.300',
        }}>
            <Stack sx={{
                p: (theme) => theme.spacing(1, 1, 1, 1),
                borderBottom: '1px solid',
                borderBottomColor: 'gery.300',
                backgroundColor: 'grey.800',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>
                <Typography sx={{ color: 'grey.0', fontSize: '14px', fontWeight: 400, }}>{isReplyToAll ? "Reply to all" : "Reply message"} </Typography>

            </Stack>
            <Stack
                // spacing={2}
                sx={{
                    // p: (theme) => theme.spacing(0, 2, 2, 2),
                    // mt: 2
                }}
            >
                <TagsInput
                    selectedTags={handleSelecetedTags}
                    fullWidth
                    variant="standard"
                    id="tags"
                    name="tags"
                    placeholder="To"
                    actionType="to"
                    value={[(mail?.isSend) ? mail?.to?.value?.[0]?.address : mail?.from?.value?.[0]?.address]}
                    endAdornment={
                        <Stack direction="row" spacing={0.5} sx={{ typography: 'subtitle2' }}>
                            {!renderCC && <Box
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': { textDecoration: 'underline' },
                                }}
                                onClick={() => setRenderCC(true)}
                            >
                                Cc
                            </Box>}
                            {!renderBCC && <Box
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': { textDecoration: 'underline' },
                                }}
                                onClick={() => setRenderBCC(true)}
                            >
                                Bcc
                            </Box>}
                        </Stack>}
                />

                {renderCC &&
                    <TagsInput
                        selectedTags={handleSelecetedTags}
                        fullWidth
                        variant="standard"
                        id="tagscc"
                        name="tagscc"
                        placeholder="CC"
                        actionType="cc"
                        value={mail?.cc?.value?.map(({ address }) => address)}
                    />
                }

                {renderBCC &&
                    <TagsInput
                        selectedTags={handleSelecetedTags}
                        fullWidth
                        variant="standard"
                        id="tagsbcc"
                        name="tagsbcc"
                        placeholder="BCC"
                        actionType="bcc"
                    />}

                {/* <Box sx={{p: (theme) => theme.spacing(0, 1, 0, 1)}}> */}
                <Editor
                    simple
                    id={"reply-mail" + mail?.id}
                    value={message}
                    onChange={(e) => handleChangeMessage(e)}
                />
                {/* </Box> */}

                {signature &&
                    <Scrollbar sx={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        padding: '10px 20px 70px 20px'
                    }}>
                        {ReactHtmlParser(allSignatures[signature]?.signature)}
                    </Scrollbar>}

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {!!attachments?.length && attachments.map((file, i) => (
                        <ComposeAttachments
                            fileName={file?.name}
                            size={file?.size}
                            key={i}
                            id={i}
                            removeAttachment={removeAttachment}
                        />
                    ))}
                </Box>


                <Stack direction="row" alignItems="center" sx={{ p: (theme) => theme.spacing(1, 1, 1, 1) }}>

                    <Stack direction="row" alignItems="center" flexGrow={1}>


                        <Tooltip title="Attach files" PopperProps={{ style: { zIndex: 9999 } }}>
                            <IconButton onClick={() => fileInput.current.click()}>
                                <SvgIcon sx={{ width: '20px', height: '20px', color: 'grey.700', }}>
                                    <Attachment45LineIcon color={'currentColor'} />
                                </SvgIcon>
                            </IconButton>

                            <input
                                ref={fileInput}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                multiple
                            // accept="image/*,video/*"
                            />
                        </Tooltip>

                        <Tooltip title="Insert emoji" PopperProps={{ style: { zIndex: 9999 } }}>
                            <IconButton onClick={handleClickEmoji}>
                                <SvgIcon sx={{ width: '20px', height: '20px', color: 'grey.700', }}>
                                    <EmotionLineIcon color={'currentColor'} />
                                </SvgIcon>
                            </IconButton>

                            <Menu
                                id="emoji-menu"
                                anchorEl={anchorElEmoji}
                                open={openEmoji}
                                onClose={handleCloseEmoji}
                                MenuListProps={{
                                    'aria-labelledby': 'emoji-icon',
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    zIndex: 9999,
                                    padding: '0px',
                                    '& .MuiPaper-root': {
                                        padding: '0px',
                                    }
                                }}
                            >
                                <EmojiMenu handleChange={handleEmojiChange} />
                            </Menu>
                        </Tooltip>

                        {/* <Tooltip title="Insert signature" PopperProps={{ style: { zIndex: 9999 } }} placement="bottom">
                            <IconButton onClick={handleClickMore}>
                                <Iconify icon="ant-design:signature-filled" />
                            </IconButton>

                            <Menu
                                id="more-menu"
                                anchorEl={anchorElMore}
                                open={openSearchFilter}
                                onClose={handleCloseMore}
                                MenuListProps={{
                                    'aria-labelledby': 'more-icon',
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    zIndex: 9999
                                }}
                            >
                                {/* <SignatureMoreMenu
                                    allSignatures={allSignatures}
                                    signature={signature}
                                    setSignature={setSignature}
                                    handleCloseMore={handleCloseMore}
                                /> */}
                        {/* </Menu>
                        </Tooltip> */}


                        <Tooltip title="Discard draft" PopperProps={{ style: { zIndex: 9999 } }}>
                            <IconButton
                                onClick={() => isReply.onFalse()}
                            >
                                {/* <Iconify icon="weui:delete-filled" /> */}
                                <SvgIcon sx={{
                                    color: 'grey.700',
                                    width: '18px',
                                    height: '18px',
                                }}>
                                    <DeleteLineIcon color={"currentColor"} />
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>


                    </Stack>



                    <Button
                        variant="outlined"
                        color="primary"
                        endIcon={<SvgIcon sx={{ with: '14px', height: '14px' }}><SendPlane2LineIcon color={'currentColor'} /></SvgIcon>}
                        onClick={() => handleSend()}
                        loading={btnLoader}
                        sx={{
                            borderRadius: '3px',
                            borderColor: 'grey.300',
                            color: 'grey.800',
                            fontWeight: 300,
                            fontSize: '14px'
                        }}
                    >
                        Send
                    </Button>
                </Stack>
            </Stack>
        </Card >
    )
}

export default MailReply;

MailReply.propTypes = {
    mail: PropTypes.object,
    maildata: PropTypes.object,
    signature: PropTypes.string,
    selectedLabelId: PropTypes.string,
    sendReply: PropTypes.func,
    isReplyToAll: PropTypes.bool,
    isLast: PropTypes.bool,
    isReply: PropTypes.bool,
    updateMailData: PropTypes.func,
    deleteDraft: PropTypes.func,
    drafts: PropTypes.object,
    rootId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};