import React, { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';

import CustomModal from 'src/components/modal/customModal';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFTextField } from 'src/components/hook-form';
import { Stack } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const InputContainer = styled('div')(() => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: '20px',
    flexDirection: 'row',
}));

const InputInnerContainer = styled('div')(() => ({
    width: 'calc(100% / 2)',
}));

const PlanForm = ({ open, onClose, plan, edit, handlePlanUpdate, handleAddPlan }) => {
    const [loadingButton, setLoadingButton] = useState(false);

    const planSchema = Yup.object().shape({
        product_id: Yup.string()
            .transform((value) => (value ? value.trim() : ''))
            .required('Product ID is required'),
        product_name: Yup.string()
            .transform((value) => (value ? value.trim() : ''))
            .required('Product Name is required'),
        plan_id: Yup.string()
            .transform((value) => (value ? value.trim() : ''))
            .required('Plan ID is required'),
        plan_name: Yup.string()
            .transform((value) => (value ? value.trim() : ''))
            .required('Plan Name is required'),
        price: Yup.number().typeError('Price is invalid').required('Price is required'),
        min_price: Yup.number().typeError('Min Price is invalid').required('Min Price is required'),
        max_price: Yup.number().typeError('Max Price is invalid').required('Max Price is required'),
        min_user: Yup.number().typeError('Min User is invalid').required('Min User is required'),
        max_user: Yup.number().typeError('Max User is invalid').required('Max User is required'),
        currency: Yup.string()
            .transform((value) => (value ? value.trim() : ''))
            .required('Currency is required'),
        description: Yup.string()
            .transform((value) => (value ? value.trim() : ''))
            .required('Description is required'),
        duration_months: Yup.number()
            .positive()
            .integer()
            .typeError('Duration Months is invalid')
            .required('Duration Months is required'),
        cgst: Yup.number().positive().integer().typeError('CGST is invalid').required('CGST is required'),
        sgst: Yup.number().positive().integer().typeError('SGST is invalid').required('SGST is required'),
        yearly_discount: Yup.number()
            .positive()
            .integer()
            .typeError('Yearly Discount is invalid')
            .required('Yearly Discount is required'),
        monthly_discount: Yup.number()
            .positive()
            .integer()
            .typeError('Monthly Discount is invalid')
            .required('Monthly Discount is required'),
        features: Yup.array()
            .of(
                Yup.object().shape({
                    feature_name: Yup.string()
                        .transform((value) => (value ? value.trim() : ''))
                        .required('Feature Name is required'),
                    feature_description: Yup.string()
                        .transform((value) => (value ? value.trim() : ''))
                        .required('Feature Description is required'),
                })
            )
            .optional(),
    });

    const methods = useForm({
        resolver: yupResolver(planSchema),
        // defaultValues: plan,
    });

    const { control } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'features',
    });

    const { setValue, handleSubmit, watch, reset } = methods;

    const values = watch();
    const onSubmit = async (data) => {
        edit ? await handlePlanUpdate(data, setLoadingButton) : await handleAddPlan(data, setLoadingButton);
    };

    useEffect(() => {
        if (plan) {
            setValue('product_id', plan.product_id);
            setValue('product_name', plan.product_name);
            setValue('plan_id', plan.plan_id);
            setValue('plan_name', plan.plan_name);
            setValue('price', plan.price);
            setValue('min_price', plan.min_price);
            setValue('max_price', plan.max_price);
            setValue('min_user', plan.min_user);
            setValue('max_user', plan.max_user);
            setValue('currency', plan.currency);
            setValue('description', plan.description);
            setValue('duration_months', plan.duration_months);
            setValue('cgst', plan.cgst);
            setValue('sgst', plan.sgst);
            setValue('yearly_discount', plan.yearly_discount);
            setValue('monthly_discount', plan.monthly_discount);
            setValue('features', plan.features);
        }
    }, [plan, reset]);

    const description = (
        <Box>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack width={'100%'} direction={'column'} gap={2} sx={{ p: '25px', overflowY: 'auto', height: '700px' }}>
                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField name="product_id" label="Product ID" placeholder="Enter Product ID." />
                        </InputInnerContainer>

                        <InputInnerContainer>
                            <RHFTextField name="product_name" label="Product Name" placeholder="Enter Product Name." />
                        </InputInnerContainer>
                    </InputContainer>

                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField name="plan_id" label="Plan ID" placeholder="Enter Plan ID." disabled={edit} />
                        </InputInnerContainer>
                        <InputInnerContainer>
                            <RHFTextField name="plan_name" label="Plan Name" placeholder="Enter Plan Name." />
                        </InputInnerContainer>
                    </InputContainer>

                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField name="price" label="Price" placeholder="Enter Price." type={'number'} />
                        </InputInnerContainer>
                        <InputInnerContainer>
                            <RHFTextField name="currency" label="Currency" placeholder="Enter Currency." />
                        </InputInnerContainer>
                    </InputContainer>

                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField name="min_price" label="Min Price" placeholder="Enter Min Price." type={'number'} />
                        </InputInnerContainer>
                        <InputInnerContainer>
                            <RHFTextField name="max_price" label="Max Price" placeholder="Enter Max Price." type={'number'} />
                        </InputInnerContainer>
                    </InputContainer>

                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField name="min_user" label="Min User" placeholder="Enter Min User." type={'number'} />
                        </InputInnerContainer>
                        <InputInnerContainer>
                            <RHFTextField name="max_user" label="Max User" placeholder="Enter Max User." type={'number'} />
                        </InputInnerContainer>
                    </InputContainer>

                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField name="sgst" label="SGST" placeholder="Enter SGST." type={'number'} />
                        </InputInnerContainer>
                        <InputInnerContainer>
                            <RHFTextField name="cgst" label="CGST" placeholder="Enter CGST." type={'number'} />
                        </InputInnerContainer>
                    </InputContainer>

                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField
                                name="monthly_discount"
                                label="Monthly Discount"
                                placeholder="Enter Monthly Discount."
                                type={'number'}
                            />
                        </InputInnerContainer>
                        <InputInnerContainer>
                            <RHFTextField
                                name="yearly_discount"
                                label="Yearly Discount"
                                placeholder="Enter Yearly Discount."
                                type={'number'}
                            />
                        </InputInnerContainer>
                    </InputContainer>

                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField
                                name="description"
                                label="Description"
                                placeholder="Enter Description."
                                type={'text'}
                                multiline
                                rows={4}
                            />
                        </InputInnerContainer>

                        <InputInnerContainer>
                            <RHFTextField
                                name="duration_months"
                                label="Duration Months"
                                placeholder="Enter Duration Months."
                                type={'number'}
                            />
                        </InputInnerContainer>
                    </InputContainer>

                    <InputContainer>
                        <Typography variant="h6">Features</Typography>
                        <Button onClick={() => append({ feature_name: '', feature_description: '' })}>Add Feature</Button>
                    </InputContainer>

                    {fields.map((item, index) => (
                        <Box key={item.id} display="flex" alignItems="center" gap={2}>
                            <InputInnerContainer>
                                <Controller
                                    name={`features[${index}].feature_name`}
                                    control={control}
                                    defaultValue={item.feature_name || ''}
                                    render={({ field }) => (
                                        <RHFTextField {...field} label="Feature Name" placeholder="Enter Feature Name." />
                                    )}
                                />
                            </InputInnerContainer>
                            <InputInnerContainer>
                                <Controller
                                    name={`features[${index}].feature_description`}
                                    control={control}
                                    defaultValue={item.feature_description || ''}
                                    render={({ field }) => (
                                        <RHFTextField
                                            {...field}
                                            label="Feature Description"
                                            placeholder="Enter Feature Description."
                                        // multiline
                                        // rows={3}
                                        />
                                    )}
                                />
                            </InputInnerContainer>
                            <IconButton onClick={() => remove(index)} color="error">
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}

                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                        <LoadingButton type="submit" variant="contained" loading={loadingButton} sx={{ width: '120px' }}>
                            Submit
                        </LoadingButton>
                    </Stack>
                </Stack>
            </FormProvider>
        </Box>
    );

    return <CustomModal open={open} title="Create Plan" description={description} close={onClose} width={'60%'} />;
};
export default PlanForm;
