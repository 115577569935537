import React, { useState } from "react";

//component
import SideBarHoverMenu from "../../components/menu/sideBarHoverMenu";

//@mui
import { styled } from "@mui/material/styles";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  useTheme,
  listItemTextClasses,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { useLocation, useNavigate } from "react-router-dom";

// Icons
import HomeIcon from "@mui/icons-material/Home";
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { checkRouteAccess } from "../../utils/common";
import MicSvg from "src/assets/svgs/micSvg";
import VoiceSideBarHoverMenu from "src/components/menu/voiceSideBarHoverMenu";

// Drawer style handler
const Drawer = styled(
  MuiDrawer,
  {}
)(({ theme }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7.5)} + 1px)`,
  // width: '68px',
  borderRight: '1px solid',
  display: "flex",
}));

const CustomListItemButton = styled(ListItemButton)({
  minHeight: 48,
  // width: '68px',
  justifyContent: "center",
  flexDirection: "column",
  gap: '3px',
  // px: 1,
  '&:hover': {
    backgroundColor: 'transparent'
  }
})

const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 0,
  justifyContent: "center",
  width: "44px",
  height: "28px",
  display: "flex",
  alignItems: "center",
  borderRadius: "15px",
  marginLeft: '16px',
  '&:hover': {
    color: theme.palette.primary.main,
    transition: 'all 0.6s ease-in-out',
  }
}))

const CustomListItemText = styled(ListItemText)(
  {
    [`& .${listItemTextClasses.primary}`]: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "18px",
    }
  }
)

const Sidebar = () => {
  const location = useLocation();
  const { palette: { mode = "light" } } = useTheme();
  const iconSx = { width: "20px", height: "20px" };
  const [custombox, setCustomBox] = useState(false);
  const [voiceCustomBox, setVoiceCustomBox] = useState(false);
  const navigate = useNavigate()
  
  const activeBgColor = mode === "light" ? "grey.300" : "#4F4F50";
  const homeSideBar = [
    {
      title: "Home",
      icon: <HomeIcon sx={iconSx} />,
      active: location?.pathname === "/home",
      path: "/home",
      disabled: false,
    },
    {
      title: "Console",
      icon: <DashboardCustomizeIcon sx={iconSx} />,
      active: ["/console_users", "/advertisement", "/branding", "/amAnalysis"].includes(location?.pathname),
      path: "/console",
      disabled: false,
    },

    {
      title: "Voice",
      icon: <MicSvg sx={iconSx} />,
      active: ["/announcementList", "/ivrList", "/ttsTemplateList"].includes(location?.pathname),
      path: "/voice",
      disabled: false,
    },
    // {
    //   title: "Voice",
    //   icon: <MicSvg sx={iconSx} />,
    //   active: ["/announcementList", "/ivrList"].includes(location?.pathname),
    //   path: "/announcementList",
    //   disabled: false,
    // },
    {
      title: "Meet",
      icon: <VideocamOutlinedIcon sx={iconSx} />,
      active: location?.pathname === "/meet",
      path: "/meet",
      disabled: false,
    },
    {
      title: "Webinar",
      icon: <VideoCameraFrontOutlinedIcon sx={iconSx} />,
      active: location?.pathname === "/webinar",
      path: "/webinar",
      disabled: false,
    },
    /* {
      title: "SMS",
      icon: <SmsOutlinedIcon sx={iconSx} />,
      active: location?.pathname === "/sms",
      path: "/sms",
      disabled: false,
    } */
  ];

  const handleNavigate = (path) => {
    if (path !== "/console" && path !== "/voice") navigate(path)

  }

  const openSlider = (path) => {
    if (path === "/console") setCustomBox(true)
    if (path === "/voice") setVoiceCustomBox(true)
  }

  const closeSlider = (path) => {
    if (path === "/console") setCustomBox(false);
    if (path === "/voice") setVoiceCustomBox(false)

  }

  return (
    <>
      <Drawer
        variant="permanent"
        PaperProps={{
          sx: {
            width: "68px",
            backgroundColor: "card.main",
            pt: '65px',
            zIndex: 1
          },
        }}
      >

        {/* Side bar list section. */}
        <List>
          {homeSideBar.filter(({ path }) => checkRouteAccess(path)).map(({ title, icon, active, path }) => (
            <Box key={title} onMouseEnter={() => openSlider(path)} onMouseLeave={() => closeSlider(path)} sx={{
              display: "flex",
            }}>

              <ListItem
                key={title}
                disablePadding
                sx={{
                  display: "block",
                  borderRadius: "10px",
                }}
                onClick={() => handleNavigate(path)}

              >
                <CustomListItemButton disableTouchRipple>

                  <CustomListItemIcon
                    sx={{
                      color: active ? "primary.main" : "grey.600",
                      backgroundColor: active ? activeBgColor : "transparent",
                      '&:hover': {
                        backgroundColor: activeBgColor,
                      }
                    }}
                  >
                    {icon}
                  </CustomListItemIcon>

                  <CustomListItemText
                    primary={title}
                    primaryTypographyProps={{
                      color: active ? "grey.900" : "grey.600",
                    }}
                  />

                </CustomListItemButton>

              </ListItem>
              {path === "/console" && <SideBarHoverMenu custombox={custombox} />}
              {path === "/voice" && <VoiceSideBarHoverMenu custombox={voiceCustomBox} />}
            </Box>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Sidebar;
