import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ReactHtmlParser from 'react-html-parser';

import { fDateTime, fToNow } from 'src/utils/format-time';

import Image from 'src/components/image';
import Lightbox, { useLightBox } from 'src/components/lightbox';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonBase, Collapse, IconButton, Link, ListItemText, SvgIcon, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Scrollbar from 'src/components/scrollbar';
import Iconify from 'src/components/iconify';
import FileThumbnail from 'src/components/file-thumbnail';
import { useBoolean } from 'src/hooks/use-boolean';
import JSZip from 'jszip';
import { useEffect, useRef } from 'react';

// ----------------------------------------------------------------------


const mailThread = [
  {

    "threadId": "1818299921593541147",
    "html": `<div dir="ltr">This is testing mail.<img width="0" height="0" class="mailtrack - img" alt="" style="display: flex" src="https://mailsuite.com/trace/mail/0dfc408494ff30304e16f674e14107c379d1654a.png?u=11481176"></div>`,
    "textAsHtml": "<p>This is testing mail.</p>",
    "subject": "Testing mail",
    "references": "",
    "date": "2024-12-13T04:58:42.000Z",
    "createdAt": "2024-12-13T05:03:57.173Z",
    "to": {
      "value": [
        {
          "address": "Tk972021@gmail.com",
          "name": "Nitesh Kushwaha"
        }
      ],
      "html": `<span class="mp_address_group"><span class="mp_address_name">Nitesh Kushwaha</span> &lt;<a href="mailto: Tk972021@gmail.com" class="mp_address_email">Tk972021@gmail.com</a>&gt;</span>`,
      "text": "\"Nitesh Kushwaha\" <Tk972021@gmail.com>"
    },
    "from": {
      "value": [
        {
          "address": "nitesh.k@sarv.com",
          "name": "Nitesh Kumar"
        }
      ],
      "html": `<span class="mp_address_group"><span class="mp_address_name">Nitesh Kumar</span> &lt;<a href="mailto: nitesh.k@sarv.com" class="mp_address_email">nitesh.k@sarv.com</a>&gt;</span>`,
      "text": `\"Nitesh Kumar\" <nitesh.k@sarv.com>`
    },
    "cc": {},
    "bcc": {},
    "messageId": "<CAFUuS3umk7BF4yx6XVa=JCoZi1x02t1Guoacc5DvnoROSThE6A@mail.gmail.com>",
    "inReplyTo": "",
    "attributes": {
      "struct": [
        {
          "type": "alternative",
          "params": {
            "boundary": "000000000000f92d7406291faf60"
          },
          "disposition": null,
          "language": null
        },
        [
          {
            "partID": "1",
            "type": "text",
            "subtype": "plain",
            "params": {
              "charset": "UTF-8"
            },
            "id": null,
            "description": null,
            "encoding": "7BIT",
            "size": 23,
            "lines": 1,
            "md5": null,
            "disposition": null,
            "language": null
          }
        ],
        [
          {
            "partID": "2",
            "type": "text",
            "subtype": "html",
            "params": {
              "charset": "UTF-8"
            },
            "id": null,
            "description": null,
            "encoding": "7BIT",
            "size": 215,
            "lines": 5,
            "md5": null,
            "disposition": null,
            "language": null
          }
        ]
      ],
      "date": "2024-12-13T04:58:54.000Z",
      "flags": [],
      "uid": 29399,
      "modseq": "2492135",
      "x-gm-labels": [],
      "x-gm-msgid": "1818299921593541147",
      "x-gm-thrid": "1818299921593541147"
    }

  },
  {
    "threadId": "1818299921593541147",
    "html": `<div dir="ltr"><div dir="ltr">Reply mail<div><br></div></div><br><img width="0" height="0" class="mailtrack - img" alt="" style="display:flex" src="https://mailsuite.com/trace/mail/4f5b47b226ac1556e13e966a35575a18375ee4d8.png?u=11481176"><div class="gmail_quote gmail_quote_container"><div dir="ltr" class="gmail_attr">On Fri, Dec 13, 2024 at 10:28 AM Nitesh Kumar &lt;<a href="mailto:nitesh.k@sarv.com">nitesh.k@sarv.com</a>&gt; wrote:<br></div><blockquote class="gmail_quote" style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex"><div dir="ltr">This is testing mail.<img width="0" height="0" alt="" style="display: flex;" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"></div> </blockquote ></div ></div > `,
    "textAsHtml": `<p>Reply mail</p><p>On Fri, Dec 13, 2024 at 10:28&#x202F;AM Nitesh Kumar &lt;<a href="mailto: nitesh.k@sarv.com">nitesh.k@sarv.com</a>&gt; wrote:</p><p>&gt; This is testing mail.<br/>&gt;</p>`,
    "subject": "Re: Testing mail",
    "references": "<CAFUuS3umk7BF4yx6XVa=JCoZi1x02t1Guoacc5DvnoROSThE6A@mail.gmail.com>",
    "date": "2024-12-13T05:03:02.000Z",
    "createdAt": "2024-12-13T05:03:59.257Z",
    "to": {
      "value": [
        {
          "address": "Tk972021@gmail.com",
          "name": "Nitesh Kushwaha"
        }
      ],
      "html": `<span class="mp_address_group"><span class="mp_address_name">Nitesh Kushwaha</span> &lt;<a href="mailto: Tk972021 @gmail.com" class="mp_address_email">Tk972021@gmail.com</a>&gt;</span>`,
      "text": `\"Nitesh Kushwaha\" <Tk972021@gmail.com>`
    },
    "from": {
      "value": [
        {
          "address": "nitesh.k@sarv.com",
          "name": "Nitesh Kumar"
        }
      ],
      "html": `<span class="mp_address_group"><span class="mp_address_name">Nitesh Kumar</span> &lt;<a href="mailto: nitesh.k@sarv.com" class="mp_address_email">nitesh.k@sarv.com</a>&gt;</span>`,
      "text": `\"Nitesh Kumar\" <nitesh.k@sarv.com>`
    },
    "cc": {},
    "bcc": {},
    "messageId": "<CAFUuS3v50VOTKVEbGX1aQj7S-BqE7if6-PuAZ9Zt=dC+iStYGQ@mail.gmail.com>",
    "inReplyTo": "<CAFUuS3umk7BF4yx6XVa=JCoZi1x02t1Guoacc5DvnoROSThE6A@mail.gmail.com>",
    "attributes": {
      "struct": [
        {
          "type": "alternative",
          "params": {
            "boundary": "000000000000758f2e06291fbfe5"
          },
          "disposition": null,
          "language": null
        },
        [
          {
            "partID": "1",
            "type": "text",
            "subtype": "plain",
            "params": {
              "charset": "UTF-8"
            },
            "id": null,
            "description": null,
            "encoding": "QUOTED-PRINTABLE",
            "size": 131,
            "lines": 3,
            "md5": null,
            "disposition": null,
            "language": null
          }
        ],
        [
          {
            "partID": "2",
            "type": "text",
            "subtype": "html",
            "params": {
              "charset": "UTF-8"
            },
            "id": null,
            "description": null,
            "encoding": "QUOTED-PRINTABLE",
            "size": 857,
            "lines": 18,
            "md5": null,
            "disposition": null,
            "language": null
          }
        ]
      ],
      "date": "2024-12-13T05:03:14.000Z",
      "flags": [],
      "uid": 29400,
      "modseq": "2492192",
      "x-gm-labels": [],
      "x-gm-msgid": "1818300194171684537",
      "x-gm-thrid": "1818299921593541147"
    }

  },
  {
    "attachments": [],
    "headers": {},
    "headerLines": [
      {
        "key": "return-path",
        "line": "Return-Path: <tk972021@gmail.com>"
      },
      {
        "key": "received",
        "line": "Received: from [127.0.0.1] ([103.255.103.3])\r\n        by smtp.gmail.com with ESMTPSA id d9443c01a7336-216637467aasm69638825ad.49.2024.12.12.21.52.35\r\n        for <nitesh.k@sarv.com>\r\n        (version=TLS1_3 cipher=TLS_AES_256_GCM_SHA384 bits=256/256);\r\n        Thu, 12 Dec 2024 21:52:36 -0800 (PST)"
      },
      {
        "key": "in-reply-to",
        "line": "In-Reply-To:\r\n <CAFUuS3umk7BF4yx6XVa=JCoZi1x02t1Guoacc5DvnoROSThE6A@mail.gmail.com>"
      },
      {
        "key": "references",
        "line": "References:\r\n <CAFUuS3umk7BF4yx6XVa=JCoZi1x02t1Guoacc5DvnoROSThE6A@mail.gmail.com>"
      },
      {
        "key": "from",
        "line": "From: tk972021@gmail.com"
      },
      {
        "key": "to",
        "line": "To: nitesh.k@sarv.com"
      },
      {
        "key": "subject",
        "line": "Subject: Re: Testing mail"
      },
      {
        "key": "message-id",
        "line": "Message-ID: <c33efbc1-871a-3a87-5570-3a3dcecfa6df@gmail.com>"
      },
      {
        "key": "content-transfer-encoding",
        "line": "Content-Transfer-Encoding: 7bit"
      },
      {
        "key": "date",
        "line": "Date: Fri, 13 Dec 2024 05:52:33 +0000"
      },
      {
        "key": "mime-version",
        "line": "MIME-Version: 1.0"
      },
      {
        "key": "content-type",
        "line": "Content-Type: text/plain; charset=utf-8"
      }
    ],
    "text": "hi\n",
    "textAsHtml": "<p>hi</p>",
    "subject": "Re: Testing mail",
    "references": "<CAFUuS3umk7BF4yx6XVa=JCoZi1x02t1Guoacc5DvnoROSThE6A@mail.gmail.com>",
    "date": "2024-12-13T05:52:33.000Z",
    "to": {
      "value": [
        {
          "address": "nitesh.k@sarv.com",
          "name": "LaLA"
        }
      ],
      "html": "<span class=\"mp_address_group\"><a href=\"mailto:nitesh.k@sarv.com\" class=\"mp_address_email\">nitesh.k@sarv.com</a></span>",
      "text": "nitesh.k@sarv.com"
    },
    "from": {
      "value": [
        {
          "address": "tk972021@gmail.com",
          "name": "Nitesh Kumar"
        }
      ],
      "html": "<span class=\"mp_address_group\"><a href=\"mailto:tk972021@gmail.com\" class=\"mp_address_email\">tk972021@gmail.com</a></span>",
      "text": "tk972021@gmail.com"
    },
    "messageId": "<c33efbc1-871a-3a87-5570-3a3dcecfa6df@gmail.com>",
    "inReplyTo": "<CAFUuS3umk7BF4yx6XVa=JCoZi1x02t1Guoacc5DvnoROSThE6A@mail.gmail.com>",
    "html": false
  }
]

const arrayBufferToBase64 = buffer => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

const handleDownloadAttachment = (file) => {
  const fileExtension = getFileExtension(file.contentType);
  const fileName = `attachment.${fileExtension}`;
  const blob = new Blob([file.content], { type: file.contentType });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName//file.name;  
  a.click();

  window.URL.revokeObjectURL(url);
};

const handleDownloadZip = async (files) => {
  const zip = new JSZip();

  files.forEach((file) => {
    zip.file(file.filename, file.content);
  });

  try {
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "attachments.zip");
  } catch (error) {
    console.error("Error generating ZIP file", error);
  }
};

const getFileExtension = (mimeType) => {
  const mimeMap = {
    'text/plain': '.txt',
    'application/json': '.json',
    'application/pdf': '.pdf',
    'image/jpeg': '.jpg',
    'image/png': '.png',
    'audio/wav': '.wav',
    "application/zip": 'zip'
  };
  return mimeMap[mimeType] || 'txt';
};

export default function KanbanDetailsCommentList({ mailThread }) {


  const RenderAttachments = ({ attachments }) => {
    const showAttachments = useBoolean();
    return (
      <Stack
        spacing={1}
        sx={{
          p: 1,
          borderRadius: 1,
          bgcolor: 'background.neutral',
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <ButtonBase
            onClick={showAttachments.onToggle}
            sx={{
              color: 'text.secondary',
              typography: 'caption',
              borderRadius: 0.5,
            }}
          >
            <Iconify icon="eva:attach-2-fill" sx={{ mr: 0.5 }} />
            {attachments?.length} attachments
            <Iconify
              icon={
                showAttachments.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'
              }
              width={16}
              sx={{ ml: 0.5 }}
            />
          </ButtonBase>

          <Button startIcon={<Iconify icon="eva:cloud-download-fill" />} onClick={() => handleDownloadZip(mail?.attachments)}>Download</Button>
        </Stack>

        <Collapse in={showAttachments.value} unmountOnExit timeout="auto">
          <Stack direction="row" flexWrap="wrap" spacing={1}>
            {attachments.map((attachment, i) => (
              <Stack
                key={attachment.id || i + 1}
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: 180,
                  height: 100,
                  flexShrink: 0,
                  borderRadius: 1,
                  overflow: 'hidden',
                  position: 'relative',
                  backgroundColor: 'background.neutral',
                  border: '0.5px dashed',
                  color: 'grey.500'
                }}
              >
                <FileThumbnail
                  tooltip
                  imageView
                  file={`data:${attachment.contentType};base64,${arrayBufferToBase64(attachment.content)}`}
                  attachFile={`data:${attachment.contentType};base64,${arrayBufferToBase64(attachment.content)}`}
                  onDownload={() => handleDownloadAttachment(attachment)}
                  contentType={attachment.contentType}
                  filename={attachment.filename}
                  sx={{ width: 54, height: 54 }}
                />
              </Stack>
            ))}
          </Stack>
        </Collapse>
      </Stack>
    )
  }

  const scrollbarRef = useRef();

  // Scroll to bottom when mailThread changes
  useEffect(() => {
    if (scrollbarRef.current) {
      const scrollElement = scrollbarRef.current;
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  }, [mailThread]);


  const renderContent = (
    <Box
      sx={{
        // py: 3,
        flexGrow: 1,
        overflow: { xs: 'auto', md: 'hidden' },
        // padding: '10px 30px 30px 30px'
        // height:"10000px"
      }}
    >
      <Scrollbar ref={scrollbarRef} >


        {!!mailThread?.length &&
          /* [...Object.values(mail?.thridData), ...Object.values(mail?.sentMails)].sort((a, b) =>
            new Date(a.date) - new Date(b.date)
          ) */mailThread.map(({ message, textAsHtml,html, from, to, cc, date, attachments, attributes = {}, id, reply: isReply
          , messageId, subject, text, folder
        }, i) => (
          <Box key={i + 1} sx={{
            borderBottom: '0.5px solid',
            borderColor: 'grey.300',
            paddingBottom: 1,
            // paddingTop: '10px',
          }}>
            <Accordion defaultExpanded>

              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header" u
              >

                <Stack spacing={2} direction="row" flexShrink={0} alignItems={"center"}>
                  <Stack
                    flexShrink={0}
                    direction="row"
                    alignItems="center"
                    sx={{
                      p: (theme) => theme.spacing(1, 1, 1, 1),
                      flexGrow: 1
                    }}
                  >
                    <Avatar
                      alt={from?.value?.[0]?.name}
                      src={from?.value?.[0]?.avatarUrl ? `${from?.value?.[0]?.avatarUrl}` : ''}
                      sx={{ mr: 2 }}
                    >
                      {from?.value?.[0]?.name?.charAt(0)?.toUpperCase()}
                    </Avatar>

                    <ListItemText
                      primary={
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '5px'
                        }}>
                          <Typography variant="body2" sx={{ color: 'grey.800', fontWeight: 400 }}>
                            {from?.value?.[0]?.name}
                          </Typography>
                          <Box component="span" sx={{ typography: 'body2', color: 'grey.1100', fontWeight: 300, fontSize: '14px' }}>
                            {` <${from?.value?.[0]?.address}>`}
                          </Box>
                        </Box>
                      }
                      secondary={
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '5px'
                        }}>
                          <Typography variant="body2" sx={{ color: 'grey.800', fontWeight: 300 }}>
                            {`To: `}
                          </Typography>
                          {to?.value.concat(cc?.value || []).map((person, index) => (
                            <Link key={person.address} sx={{ color: 'grey.1100', fontWeight: 300 }}>
                              {to?.value.concat(cc?.value || []).length - 1 === index ? `${person.address}` : `${person.address}, `}
                            </Link>
                          ))}
                        </Box>
                      }
                      secondaryTypographyProps={{
                        mt: 0.5,
                        noWrap: true,
                        component: 'span',
                        typography: 'caption',
                      }}
                    />
                  </Stack>

                  <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: (theme) => theme.spacing(1) }}>
                    {/* <Stack direction="row" alignItems="center" justifyContent="flex-end">
                  {!!attachments.length && <IconButton size="small">
                    <Iconify width={18} icon="material-symbols-light:attachment" />
                  </IconButton>}

                </Stack> */}

                    <Typography variant="caption" noWrap sx={{ color: 'grey.1100', mr: '5px' }}>
                      {date && fDateTime(date)}
                    </Typography>

                    {/* <Star
                  flags={attributes?.flags}
                  markFlag={markFlag}
                  uid={attributes?.uid}
                  id={mail?.thridId}
                  folder={folder}
                /> */}

                    {/* <Tooltip title={"Reply"} placement={'bottom'}>
                  <IconButton
                    size="small"
                    onClick={() => updateMailData({ reply: true, updateId: attributes?.uid, rootId: mail?.id, additionalData: {}, action: 'add' })}
                    sx={{ ml: '-8px' }}
                  >
                    <Iconify icon="solar:reply-bold" />
                    <SvgIcon sx={{
                      color: 'grey.700',
                      width: '16px',
                      height: '16px',
                    }}>
                      <CornerUpLeftLineIcon color={"currentColor"} />
                    </SvgIcon>
                  </IconButton>
                </Tooltip> */}
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ ml: 7, mr: 7, color: 'text.primary' }}>
                  {message ? ReactHtmlParser(message) : ReactHtmlParser(textAsHtml || html)}
                </Box>
              </AccordionDetails>

              {/* {(!!attachments.length && <Stack sx={{ p: 1 }}> {renderAttachments} </Stack>)}

              

            {(isReply && !mail?.isLast) && <MailReply
              mail={{ message, from, to, cc, date, attachments, attributes, id, thridId: mail?.thridId, reply, messageId, subject }}
              signature={signature}
              sendReply={sendReply}
              isReplyToAll={replyToAll}
              updateMailData={updateMailData}
              rootId={mail?.thridId}
            />} */}

              {(!!attachments.length && <Stack sx={{ p: 1 }}>
                {/* {renderAttachments(attachments)} */}
                <RenderAttachments attachments={attachments} />
              </Stack>)}

            </Accordion>
          </Box>
        ))
        }


        {/* {mail?.folder === 'Sent' && ReactHtmlParser(mail?.textAsHtml)} */}

        {/* {(mail?.isLast) && <MailReply
          mail={[...Object.values(mail?.thridData || {})].sort((a, b) => new Date(a.date) - new Date(b.date))?.at(-1)}
          signature={signature}
          sendReply={sendReply}
          isReplyToAll={false}
          updateMailData={updateMailData}
          rootId={mail?.thridId}
        />} */}

        {/* {(mail?.isReplyAll) && <MailReply
          mail={mail}
          signature={signature}
          sendReply={sendReply}
          isReplyToAll={mail?.isReplyAll}
          updateMailData={updateMailData}
          rootId={mail?.thridId}
        />} */}

        {/* {(!reply && !forword) && replyNforword} */}


        {/* {(mail?.forward || forword) && <ForwordMail
          mail={mail}
          signature={signature}
          sendReply={sendReply}
          updateMailData={updateMailData}
          rootId={mail?.thridId}
          setForword={setForword}
        />} */}
      </Scrollbar>
    </Box>
  );


  return <>
    {renderContent
    }
  </>
}

KanbanDetailsCommentList.propTypes = {
  comments: PropTypes.array,
};
