import React, { useEffect, useState, input } from 'react'
import { checkUserRole } from '../../utils/common';

import { TextField, InputLabel, FormControl, Select, MenuItem, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
const FormSection = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    gap: '8px',
    height: '40px'

}));

const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#D0D0D1",
    borderRadius: '6px',
    '& input': {
        color: "#5B5C5D",
    },
    width: '350px',
    height: 'auto'
}));

const BasicSelect = styled(Select)(({ theme }) => ({
    backgroundColor: "#D0D0D1",
    borderColor: "#5B5C5D",
    borderRadius: '6px',
    '& input': {
        color: "#5B5C5D",
    },
    width: '350px',
}));

const DetailsForm = ({ email, setEmail, userType, setUserType, handleAddUser, group, setGroup, edit, addUserLoader }) => {
    const [selectDisable, setSelectDisable] = useState(false)
    const handleChange = (value) => {
        setEmail(value)
    }

    const handleSelect = (event) => {
        setUserType(event.target.value);
    };

    const handleGroupSelect = (event) => {
        setGroup(event.target.value)
    }

    useEffect(() => {
        if (checkUserRole() === "admin") {
            if (edit) {
                setSelectDisable(true)
            }
            else {
                setUserType("agent")
                setSelectDisable(true)
            }
        }
    })

    return (
        <FormSection>
            <input
                style={{
                    width: '350px',
                    backgroundColor: "#D0D0D1",
                    borderRadius: '6px',
                    border: 'red',
                    padding: '10px',

                    boxSizing: 'border-box',
                    fontSize: '14px',
                }}
                type="text"
                id="emailId"
                onChange={(e) => handleChange(e.target.value)}
                value={email}
                size='small'
                placeholder="Please enter email"
                disabled={edit}
            />


            <FormControl size="small" disabled={selectDisable}>
                <InputLabel id="select-label">Select user type</InputLabel>
                <BasicSelect
                    labelId="select-label"
                    id="select"
                    value={userType}
                    label="Select user type"
                    onChange={handleSelect}
                >
                    <MenuItem value={"admin"}>Admin</MenuItem>
                    <MenuItem value={"agent"}>Agent</MenuItem>
                </BasicSelect>
            </FormControl>

            <FormControl size="small" >
                <InputLabel id="select-label">Select user Group</InputLabel>
                <BasicSelect
                    labelId="select-label"
                    id="select=group"
                    value={group}
                    label="Select user Group"
                    onChange={handleGroupSelect}
                >
                    <MenuItem value={"sales"}>Sales</MenuItem>
                    <MenuItem value={"support"}>Support</MenuItem>
                    <MenuItem value={"development"}>Development</MenuItem>
                </BasicSelect>
            </FormControl>

            <LoadingButton
                variant='contained'
                sx={{ boxShadow: 'none' }}

                onClick={() => handleAddUser()}
                loading={addUserLoader}
            >
                {edit ? "Update user" : "Add user"}
            </LoadingButton>
        </FormSection>
    )
}

export default DetailsForm