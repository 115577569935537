import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';

import { useBoolean } from 'src/hooks/use-boolean';

/* import {
  createTask,
  updateTask,
  deleteTask,
  clearColumn,
  updateColumn,
  deleteColumn,
} from 'src/api/kanban'; */

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';

import KanbanTaskAdd from './kanban-task-add';
import KanbanTaskItem from './kanban-task-item';
import KanbanColumnToolBar from './kanban-column-tool-bar';

// ----------------------------------------------------------------------

export default function KanbanColumn({ column, tasks, index, board, setBoard, handleEmit, ticketType, fetchTask, syncFilteredTickets }) {
  const { enqueueSnackbar } = useSnackbar();
  const openAddTask = useBoolean();

  const handleUpdateColumn = useCallback(
    async (columnName) => {
      try {
        if (!columnName.trim()) return
        if (column.name !== columnName) {
          // updateColumn(column.id, columnName);
          const data = { id: column.id, newName: columnName };
          handleEmit(data, "updateColumn", (err, data) => {
            if (!err) {
              enqueueSnackbar('Column update Successfully!', {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              });
              setBoard((prevBoard) => {
                const newBoard = { ...prevBoard };
                const newColumn = newBoard.columns[column.id]
                newColumn.name = columnName;
                newBoard.columns[column.id] = newColumn;
                return newBoard;
              })
            }
            else {
              enqueueSnackbar(data, {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: "error"
              });
            }
          })
        }
      } catch (error) {
        console.error(error);
      }
    },
    [board, column?.id, column?.name, enqueueSnackbar]
  );

  const handleClearColumn = useCallback(async () => {
    try {
      // clearColumn(column.id);
    } catch (error) {
      console.error(error);
    }
  }, [column?.id]);

  const handleDeleteColumn = useCallback(async () => {
    try {
      let error;
      setBoard((prevBoard) => {
        if (prevBoard.columns[column.id].taskIds.length > 0) {
          error = true;
        }
        return prevBoard;
      })
      if (error) {
        return enqueueSnackbar("This Column can not be deleted.", {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: "error"
        });
      }

      handleEmit({ id: column.id }, "deleteColumn", (err, data) => {
        if (!err) {
          setBoard((prevBoard) => {
            const newBoard = { ...prevBoard }
            delete newBoard.columns[column.id];

            const index = newBoard.ordered.indexOf(column.id);
            newBoard.ordered.splice(index, 1);

            return newBoard;
          })
          enqueueSnackbar('Delete success!', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
        else {
          enqueueSnackbar(data, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: "error"
          });
          return
        }
      })
    } catch (error) {
      console.error(error);
    }
  }, [column?.id, enqueueSnackbar]);

  const handleAddTask = useCallback(
    async (taskData) => {
      try {
        // createTask(column.id, taskData);

        openAddTask.onFalse();
      } catch (error) {
        console.error(error);
      }
    },
    [column?.id, openAddTask]
  );

  const handleUpdateTask = useCallback(async (data) => {
    try {
      setBoard((prevBoard) => {
        const newBoard = { ...prevBoard };
        newBoard.tasks[data.id] = data;
        return newBoard;
      })
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleDeleteTask = useCallback(
    async (taskId) => {
      try {
        // deleteTask(column.id, taskId);
        handleEmit({ taskId, columnId: column?.id }, "deleteTask", (err, data) => {
          if (!err) {
            enqueueSnackbar('Task Deleted successfully.', {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
            setBoard((prevBoard) => {
              const newBoard = { ...prevBoard };
              delete newBoard.tasks[taskId];

              const index = newBoard.columns[column?.id].taskIds.indexOf(taskId);
              if (index !== -1) {
                newBoard.columns[column?.id].taskIds.splice(index, 1);
              }

              return newBoard;
            })
            syncFilteredTickets(column.id);
          }
          else {
            enqueueSnackbar('Insufficient permissions to delete.', {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: "error"
            });
          }
        })

      } catch (error) {
        console.error(error);
      }
    },
    [column?.id, enqueueSnackbar]
  );

  const renderAddTask = (
    <Stack
      spacing={2}
      sx={{
        pb: 3,
      }}
    >
      {openAddTask.value && (
        <KanbanTaskAdd
          status={column?.name}
          onAddTask={handleAddTask}
          onCloseAddTask={openAddTask.onFalse}
        />
      )}

      <Button
        fullWidth
        size="large"
        color="inherit"
        startIcon={
          <Iconify
            icon={openAddTask.value ? 'solar:close-circle-broken' : 'mingcute:add-line'}
            width={18}
            sx={{ mr: -0.5 }}
          />
        }
        onClick={openAddTask.onToggle}
        sx={{ fontSize: 14 }}
      >
        {openAddTask.value ? 'Close' : 'Add Task'}
      </Button>
    </Stack>
  );
  const numberOfItem = board.filterTickets[column.id].taskIds;
  return (
    <Draggable draggableId={column?.id} index={index}>
      {(provided, snapshot) => (
        <Paper
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{
            px: 2,
            borderRadius: 2,
            bgcolor: 'background.neutral',
            ...(snapshot.isDragging && {
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.24),
            }),
          }}
        >
          <Stack {...provided.dragHandleProps}>
            <KanbanColumnToolBar
              columnName={column?.name}
              onUpdateColumn={handleUpdateColumn}
              onClearColumn={handleClearColumn}
              onDeleteColumn={handleDeleteColumn}
              columnLength={numberOfItem?.length}
            />

            <Droppable droppableId={column?.id} type="TASK">
              {(dropProvided) => (
                <Stack
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}
                  spacing={2}
                  sx={{
                    py: 3,
                    width: 280,
                  }}
                >
                  {/* {column?.taskIds.filter((task) => tasks[task]).map((taskId, taskIndex) => ( */}
                  {board.filterTickets[column.id].taskIds.filter((task) => tasks[task]).map((taskId, taskIndex) => (

                    <KanbanTaskItem
                      key={taskId}
                      index={taskIndex}
                      task={tasks[taskId]}
                      onUpdateTask={handleUpdateTask}
                      onDeleteTask={() => handleDeleteTask(taskId)}
                      board={board}
                      setBoard={setBoard}
                      handleEmit={handleEmit}
                      ticketType={ticketType}
                      columnId={column?.id}
                      syncFilteredTickets={syncFilteredTickets}
                    />

                  ))}
                  {dropProvided.placeholder}
                  <Button
                    onClick={() => fetchTask(column.id, board.filterTickets)}
                    sx={{
                      display: (board.filterTickets[column.id].taskIds.length > board.filterTickets[column.id].fetchedCount) ? "" : "none"
                    }}
                  >Load More</Button>
                </Stack>
              )}
            </Droppable>


            {/* {renderAddTask} */}
          </Stack>
        </Paper >
      )
      }

    </Draggable >
  );
}

KanbanColumn.propTypes = {
  column: PropTypes.object,
  index: PropTypes.number,
  tasks: PropTypes.object,
};
