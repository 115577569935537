
// eslint-disable-next-line
const SendPlane2LineIcon = ({ color }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.5 1.3457C3.58425 1.3457 3.66714 1.36699 3.74096 1.40759L22.2034 11.5619C22.4454 11.695 22.5337 11.999 22.4006 12.241C22.3549 12.3241 22.2865 12.3925 22.2034 12.4382L3.74096 22.5925C3.499 22.7256 3.19497 22.6373 3.06189 22.3954C3.02129 22.3215 3 22.2387 3 22.1544V1.8457C3 1.56956 3.22386 1.3457 3.5 1.3457ZM5 4.38261V11H10V13H5V19.6175L18.8499 12L5 4.38261Z" fill={color} />
        </svg>
    )
}

export default SendPlane2LineIcon