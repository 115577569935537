import Paper from '@mui/material/Paper';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import PropTypes from 'prop-types'

export default function EmojiMenu({ handleChange }) {

    const handleEmoji = (data) => {
        console.log(data)
        const { native } = data || {}
        handleChange(native)
    }

    return (
        <Paper sx={{ width: 'auto', padding: '0px' }}>
            <Picker data={data} onEmojiSelect={handleEmoji} />
        </Paper>
    );
}

EmojiMenu.propTypes = {
    handleChange: PropTypes.func
}
