import React, { useState } from 'react';
import Fade from '@mui/material/Fade';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Divider, Dialog, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { axiosPostCall } from '../../services/apisCall';
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />
});

const PreviewComponent = ({ open, setOpen, order, openModal, setOpenModal, userId }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState()
    const handleClose = () => {
        setOpen(false);
    };
    const handleApprove = () => {
        sendInvoice("Approved")
    }
    const handleReject = () => {
        sendInvoice('Rejected')
    }

    if (!order || !order.fromAddress || !order.toAddress || !order.items) {
        return (
            <Dialog open={open} onClose={handleClose}>
                <Box sx={{ p: 4, textAlign: 'center' }}>
                    <Typography variant="h6">Loading...</Typography>
                </Box>
            </Dialog>
        );
    }
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-IN').format(value);
    };
    const AddressSection = ({ title, address }) => (
        <Box sx={{ width: '40%', boxShadow: 1, border: '1px solid #ddd', p: 2, borderRadius: '10px' }}>
            <Typography
                variant="h6"
                sx={{
                    fontWeight: 'bold',
                    color: 'gray',
                }}
            >
                {title || "--"}
            </Typography>
            <Divider sx={{ mb: 1 }} />
            {Object.entries(address).map(([key, value], index) => (
                <Typography
                    key={key}
                    sx={{
                        color: index === 0 ? 'gray' : 'gray',
                        fontWeight: index === 0 ? 'bold' : 'normal',
                    }}
                >
                    {value || "--"}
                </Typography>
            ))}
        </Box>
    );

    const sendInvoice = async (data) => {
        try {
            setLoading(true)
            if (data === 'Approved') order.status = "Approved";
            if (data === 'Rejected') order.status = "Rejected";
            order.flag = 1;
            order.user = userId;
            if (data === 'Approved') order.action = "Approved";
            if (data === 'Rejected') order.action = "Rejected";
            const result = await axiosPostCall("sendInvoice", { formData: order });
            if (result.message === 'Invoice sent successfully') {
                setLoading(false)
                setOpen(false), setOpenModal(false)
                if (data === 'Approved') {
                    renderSuccessMessage('Approved successfully!')
                }
                if (data === "Rejected") {
                    renderSuccessMessage('Rejected!')
                }
            }

        } catch (error) {
            console.error(error)

        }
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            sx={{
                "& .MuiDialog-container": {
                    alignItems: "center",
                    justifyContent: 'center'
                },
                "& .MuiPaper-root": {
                    margin: '20px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.04)',
                    minWidth: '1178px',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    overflowY: 'auto',
                }
            }}
            hideBackdrop={false}
        >
            <Box sx={{
                width: '100%',
                maxWidth: '1250px',
                margin: '0 auto',
                padding: '40px',
                border: '1px solid #ccc',
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Box>

                    <img src='/images/Sarv-Icon.svg' style={{ width: '15%', height: '15%', marginLeft: '-148%', marginTop: '-2%' }} />
                </Box>
                <IconButton
                    id="modal-close-icon"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 15,
                        top: 10
                    }}>
                    <CloseIcon />
                </IconButton>

                <Typography variant="h5" align="center" sx={{ marginBottom: '20px', fontWeight: 'bold', marginTop: '-4%' }}>Invoice Preview</Typography>


                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 2, borderRadius: '10px', marginTop: '30px' }}>
                    <AddressSection title="From" address={order.fromAddress} />
                    <AddressSection title="To" address={order.toAddress} />
                </Box>

                <Typography variant="h6" sx={{ mb: '10px', fontWeight: 'bold', width: '100%', mt: '12px' }}>Services</Typography>
                <TableContainer component={Paper} sx={{ border: '1px solid #ddd' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Product </TableCell>
                                <TableCell>Plan</TableCell>
                                <TableCell align="center">Price</TableCell>
                                <TableCell align="center">Quantity(Unit)</TableCell>
                                <TableCell align="center">Tax(%)</TableCell>
                                <TableCell align="center">Tax Amount(₹)</TableCell>
                                <TableCell align="center">Discount(%)</TableCell>
                                <TableCell align="center">Discount Amount(₹)</TableCell>
                                <TableCell align="center">Amount(₹)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order.items.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.product || "--"}</TableCell>
                                    <TableCell>{item.plan || "--"}</TableCell>
                                    <TableCell align="center">{item.rate || "--"}</TableCell>
                                    <TableCell align="center">{item.quantity || "--"}</TableCell>
                                    <TableCell align="center">{item.taxRate || "--"}</TableCell>
                                    <TableCell align="center">{item.taxAmount || "--"}</TableCell>
                                    <TableCell align="center">{item.discount || "--"}</TableCell>
                                    <TableCell align="center">{item.discountAmount || "--"}</TableCell>
                                    <TableCell align="center">{item.amount?.toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ marginTop: '8px', width: '100%' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Summary</Typography>
                    <Divider sx={{ marginBottom: '10px' }} />
                    {[
                        { label: 'SubTotal', value: order.totalSubTotal },
                        { label: 'Tax', value: order.totalTax },
                        { label: 'Discount', value: order.totalDiscount }
                    ].map((item, index) => (
                        <Box key={index} sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <Typography sx={{ textAlign: 'right', width: '80%', fontWeight: 'normal' }}>{item.label} :</Typography>
                            <Typography sx={{ textAlign: 'right', width: '20%' }}>{item.value?.toFixed(2)}</Typography>
                        </Box>
                    ))}

                    <Divider sx={{ marginBottom: '10px', width: '27%', marginLeft: 'auto' }} />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Typography sx={{ textAlign: 'right', width: '80%', fontWeight: 'bold' }}>Total : </Typography>
                        <Typography sx={{ textAlign: 'right', width: '20%' }}>₹ {order.netPayable?.toFixed(2)}</Typography>
                    </Box>
                </Box>


                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    pt: '10px',
                    pb: '20px',
                    gap: "15px",
                    mt: 5
                }}>
                    <LoadingButton
                        variant='outlined'
                        onClick={handleClose}
                    >
                        Close
                    </LoadingButton>
                    {open === "Approved" && <LoadingButton
                        variant='outlined'
                        onClick={handleApprove}
                        color='success'
                    >
                        {loading ? <><CircularProgress size={17} sx={{ color: "#1976d2", marginRight: '8px' }} /> Sending... </> : 'Approve'}
                    </LoadingButton>}
                    {open === "Rejected" && <LoadingButton
                        variant='outlined'
                        onClick={handleReject}
                        color='warning'
                    >
                        {loading ? <><CircularProgress size={17} sx={{ color: "#1976d2", marginRight: '8px' }} /> Sending... </> : 'Reject'}

                    </LoadingButton>}

                    {/* <LoadingButton
                        variant='outlined'
                        color='primary'
                    >
                        Print
                    </LoadingButton> */}
                </Box>
            </Box>
        </Dialog>
    );
};
export default PreviewComponent;
