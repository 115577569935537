import React from 'react';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';

export default function ChipsProducts({ initialChips, onChipUpdate }) {
  const [chipData, setChipData] = React.useState(initialChips.slice(0, 2));
  const [remainingChips, setRemainingChips] = React.useState(initialChips.slice(2));
  const [selectedChip, setSelectedChip] = React.useState('');

  const handleSelectChip = (selectedKey) => {
    const chipToAdd = remainingChips.find((chip) => chip.key === selectedKey);
    if (chipToAdd) {
      const updatedChips = [...chipData, chipToAdd];
      setChipData(updatedChips);
      setRemainingChips((chips) => chips.filter((chip) => chip.key !== selectedKey));
      setSelectedChip('');
      onChipUpdate && onChipUpdate(updatedChips);
    }
  };

  const handleDeleteChip = (chipToDelete) => () => {
    const updatedChips = chipData.filter((chip) => chip.key !== chipToDelete.key);
    setChipData(updatedChips);
    setRemainingChips((prev) => [...prev, chipToDelete]);
    onChipUpdate && onChipUpdate(updatedChips);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 24, justifyContent: 'flex-start' }}>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        {chipData.map((data) => (
          <Chip
            key={data.key}
            label={data.label}
            color={data.color}
            onDelete={handleDeleteChip(data)}
            variant="outlined"
            size="small"
          />
        ))}
      </Box>

      <Select
        value={selectedChip}
        onChange={(e) => handleSelectChip(e.target.value)}
        displayEmpty
        sx={{ width: 'auto', minWidth: 150 }}
        size="small"
      >
        <MenuItem value="" disabled>
          Select product
        </MenuItem>
        {remainingChips.map((chip) => (
          <MenuItem key={chip.key} value={chip.key}>
            {chip.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );

}
