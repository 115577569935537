
import { useState } from 'react';

// import { useResponsive } from 'src/hooks/use-responsive';
// import { usePopover } from 'src/components/custom-popover';

export function useEmployee({ ws }) {
    const [list, setList] = useState([])
    const [listLoading, setListLoading] = useState(true)

    const fetchLogs = async (data) => {
        try {
            setListLoading(true);
            setList([])
            ws.emit("e_list", data, (err, response) => {
                console.log(err, response || [])
                const sortedRes = (response || []).reverse()
                console.log(sortedRes)
                setList(sortedRes || [])
            });

        } catch (error) {
            console.log(error)
            return error?.message?.data?.message || error?.message || "Internel server error";
        } finally {
            setListLoading(false);
        }
    }

    /*  const calendarRef = useRef(null);
     
     const calendarEl = calendarRef.current;
     
     //   const smUp = useResponsive('up', 'sm');
     
     const [date, setDate] = useState(new Date());
     
     const [openForm, setOpenForm] = useState(false);
     
     //   const popover = usePopover()
     
     const [selectEventId, setSelectEventId] = useState('');
     
     const [selectedRange, setSelectedRange] = useState(null);
     
     //   const [view, setView] = useState(smUp ? 'dayGridMonth' : 'listWeek');
     
     const onOpenForm = useCallback(() => {
         setOpenForm(true);
     }, []);
     
     //   const onOpenDetails = useCallback((el) => {
     //     popover.onOpen(el, true)
     //   }, [popover]);
     
     const onCloseForm = useCallback(() => {
         setOpenForm(false);
         setSelectedRange(null);
         setSelectEventId('');
     }, []);
     
     const onInitialView = useCallback(() => {
         if (calendarEl) {
             const calendarApi = calendarEl.getApi();
     
             //   const newView = smUp ? 'dayGridMonth' : 'listWeek';
             calendarApi.changeView(newView);
             setView(newView);
         }
     }, [calendarEl]);
     
     const onChangeView = useCallback(
         (newView) => {
             if (calendarEl) {
                 const calendarApi = calendarEl.getApi();
     
                 calendarApi.changeView(newView);
                 setView(newView);
             }
         },
         [calendarEl]
     );
     
     const onDateToday = useCallback(() => {
         if (calendarEl) {
             const calendarApi = calendarEl.getApi();
     
             calendarApi.today();
             setDate(calendarApi.getDate());
         }
     }, [calendarEl]);
     
     const onDatePrev = useCallback(() => {
         if (calendarEl) {
             const calendarApi = calendarEl.getApi();
     
             calendarApi.prev();
             setDate(calendarApi.getDate());
         }
     }, [calendarEl]);
     
     const onDateNext = useCallback(() => {
         if (calendarEl) {
             const calendarApi = calendarEl.getApi();
     
             calendarApi.next();
             setDate(calendarApi.getDate());
         }
     }, [calendarEl]);
     
     const onSelectRange = useCallback(
         (arg) => {
             if (calendarEl) {
                 const calendarApi = calendarEl.getApi();
     
                 calendarApi.unselect();
             }
     
             onOpenForm();
             setSelectedRange({ start: arg.startStr, end: arg.endStr });
         },
         [calendarEl, onOpenForm]
     );
     
     const onClickEvent = useCallback(
         (arg) => {
             const { event, el } = arg;
     
             onOpenDetails(el)
             setSelectEventId(event.id);
         },
         [onOpenDetails]
     );
     
     const onResizeEvent = useCallback((arg, updateEvent) => {
         const { event } = arg;
     
         updateEvent({
             id: event.id,
             allDay: event.allDay,
             start: event.startStr,
             end: event.endStr,
         });
     }, []);
     
     const onDropEvent = useCallback((arg, updateEvent) => {
         const { event } = arg;
     
         updateEvent({
             id: event.id,
             allDay: event.allDay,
             start: event.startStr,
             end: event.endStr,
         });
     }, []);
     
     const onClickEventInFilters = useCallback(
         (eventId) => {
             if (eventId) {
                 onOpenForm();
                 setSelectEventId(eventId);
             }
         },
         [onOpenForm]
     );
     
     const onApplyDate = useCallback((arg) => {
         const { changedDate, } = arg;
         if (changedDate?.getMonth() !== date.getMonth()) {
             setDate(changedDate)
     
             const calendarEl = calendarRef.current?.getApi();
             if (calendarEl) {
                 const formatedDate = moment(changedDate).format('YYYY-MM-DD');
                 calendarEl.gotoDate(formatedDate);
             }
         }
     }, [date]) */

    return {
        fetchLogs,
        list,
        listLoading,
        // calendarRef,
        // //
        // view,
        // date,
        // //
        // onDatePrev,
        // onDateNext,
        // onDateToday,
        // onDropEvent,
        // onClickEvent,
        // onChangeView,
        // onSelectRange,
        // onResizeEvent,
        // onInitialView,
        // //
        // openForm,
        // onOpenForm,
        // onCloseForm,

        // popover,
        // //
        // selectEventId,
        // selectedRange,
        // //
        // onClickEventInFilters,
        // onApplyDate
    };
}
