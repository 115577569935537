import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { Box, Button, Typography, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import pageTitle from "../utils/pageTitles.json";
import Iconify from 'src/components/iconify';
import EmpListTable from 'src/sections/employee/list';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';

const MainSection = styled('div')(() => ({
    width: '100%',
    padding: '64px 20px 0px 88px',
    backgroundColor: "rgb(240, 240, 240)",
    height: '100vh'
}));

const MainSectionInner = styled('div')(({ theme }) => ({
    backgroundColor: "#fff",
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    height: `calc(${window.innerHeight}px - 172px)`,
    borderRadius: '6px',
    border: '1px solid',
    borderColor: theme.palette.grey[300]
}));

const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "20px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
}));

const Employee = () => {
    const [nameInfo, setNameInfo] = useState({ name: "" })
    const [filters, setFilters] = useState({ type: 'today', department: 'HR' });
    // const [department, setDepartment] = React.useState('');

    const handleFilterChanges = (date, type) => {
        type = type === "yesterday" ? 'today' : 'yesterday'
        const data = {
            date: type === "yesterday" ? new Date(date.setDate(date.getDate() - 1)) : new Date(),
            type
        };

        setFilters({ ...filters, ...data })
    }

    const handleDateChange = (value) => {
        setFilters({ ...filters, date: value, type: 'today' })
    }

    const handleDepartmentChange = (event) => {
        setFilters({ ...filters, department: event.target.value })
    };

    useEffect(() => {
        try {
            const token = localStorage.getItem('token');

            const jwtInfo = jwtDecode(token);

            setNameInfo({ name: jwtInfo?.userName || "", "role": jwtInfo?.role, email: jwtInfo?.email })
        } catch (error) {

        }
    }, [])

    return (
        <>
            <Helmet>
                <title> {`${pageTitle?.amAnalysis}`} </title>
            </Helmet>
            <MainSection>
                <HeadingSection>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Box sx={{
                            widht: '30px',
                            height: '30px',
                            mt: 0.5
                        }}>
                            {/* <AssessmentIcon /> */}
                            <Iconify icon="twemoji:waving-hand" />
                            {/* <iconify-icon icon="twemoji:waving-hand" width="36" height="36"></iconify-icon> */}
                        </Box>
                        <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
                            Hello {nameInfo?.name}
                        </Typography>
                    </Box>

                    {/* nameInfo?.role === "superAdmin" */["rakesh.k@sarv.com", "rc@sarv.com", "prk@sarv.com"].includes(nameInfo?.email) && <Box className="filter" sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '6px',
                        alignItems: 'center',
                    }}>
                        {/* <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: '14px' }} >fil</Typography> */}

                        <FormControl sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '6px',
                            alignItems: 'center'
                        }}>

                            <Button onClick={() => handleFilterChanges(new Date(), filters?.type)} size='large' variant="outlined">
                                {filters?.type === "yesterday" ? "Today" : "Yesterday"}
                            </Button>

                            <FormControl>
                                <InputLabel id="select-label">Select Department</InputLabel>
                                <Select
                                    labelId="select-label"
                                    id="simple-select"
                                    value={filters?.department}
                                    label="Select Department"
                                    defaultValue={"Support"}
                                    onChange={handleDepartmentChange}
                                    sx={{ minWidth: '200px' }}
                                >
                                    <MenuItem value={"Development"}>Development</MenuItem>
                                    <MenuItem value={"Support"}>Support</MenuItem>
                                    <MenuItem value={"Staff"}>Staff</MenuItem>
                                    <MenuItem value={"HR"}>HR</MenuItem>
                                    <MenuItem value={"Content Writing"}>Content Writing</MenuItem>
                                    <MenuItem value={"Sales & Marketing"}>Sales & Marketing</MenuItem>
                                </Select>
                            </FormControl>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="Select date filter" value={dayjs(filters?.date)} onChange={handleDateChange} />
                            </LocalizationProvider>
                        </FormControl>

                    </Box>
                    }

                </HeadingSection>

                <MainSectionInner>
                    <EmpListTable filters={filters} />
                </MainSectionInner>
            </MainSection>
        </>
    )
}

export default Employee