import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, List, ListItem, Chip, Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Typography, styled } from "@mui/material";
import moment from "moment";
import { axiosGetCall, axiosPostCall } from '../../services/apisCall';
import NetworkError from '../../components/Error/networkError';
import { CustomSkeleton } from '../../sections/skeleton/skeletonLoader';
import VerifiedIcon from '@mui/icons-material/Verified';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CustomMenu } from '../../components/menu/customMenu';
import PreviewComponent from './preview';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Icon } from '@iconify/react';
import { TokenContext } from '.././../context/TokenContext';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileUploadDialog from './FileUploadDialog';
import { useSnackbar } from 'src/components/snackbar';
import { CircularProgress } from '@mui/material'
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';
import UpdateIcon from '@mui/icons-material/Update';
import PaymentForm from "./paymentForm";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Badge from '@mui/material/Badge';
import FiberManualRecordTwoToneIcon from '@mui/icons-material/FiberManualRecordTwoTone';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));


export default function BillingList() {
    const navigate = useNavigate()
    const { token } = React.useContext(TokenContext);
    const { enqueueSnackbar } = useSnackbar();
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [networkError, setNetworkError] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [openModal, setOpenModal] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState("");
    const [openPreview, setOpenPreview] = React.useState(null);
    const [userId, setUserId] = React.useState('')
    const [userRole, setUserRole] = React.useState('')
    const [user, setUser] = React.useState(false)
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogUpload, setOpenDialogUpload] = React.useState(false);
    const [loadingState, setLoadingState] = React.useState(false);
    const [openForm, setOpenForm] = React.useState(false)

    const columns = [
        { id: "fullName", label: "Customer Name", minWidth: 110, align: 'start' },
        { id: "toAddress", label: "Customer Email", minWidth: 110, align: 'center' },
        { id: "fromAddress", label: "From Email", minWidth: 110, align: 'center' },
        { id: "items", label: "Services", minWidth: 110, align: 'center' },
        { id: "date", label: "Created By", minWidth: 110, align: 'center' },
        { id: "date", label: "Created At", minWidth: 110, align: 'center' },
        { id: "totalDiscount", label: "Total Discount", minWidth: 110, align: 'center' },
        { id: "totalTax", label: "Total Tax", minWidth: 110, align: 'center' },
        { id: "netPayable", label: "Net Payable(₹)", minWidth: 110, align: 'center' },
        { id: "status", label: "Status", minWidth: 110, align: 'center' },
        { id: "payment", label: "Payment Status", minWidth: 110, align: 'center' },
        { id: "expireStatus", label: "Expire Status", minWidth: 110, align: 'center' },
        { id: "status", label: "Action", minWidth: 110, align: 'center' },
    ];
    React.useEffect(() => {
        if (token) {
            const { email = "", role = "" } = jwtDecode(token);
            setUserId(email)
            setUserRole(role)
        }
    }, [user])

    const getBillingList = async () => {
        try {
            const { result } = await axiosGetCall("getQuotationList");
            if (result) setRows(result);
            setLoading(false);
        } catch (error) {
            setNetworkError(true)
            setNetworkError(false)
            console.error("Error fetching billing list:", error);
            setRows([]);
            setLoading(false);
        }
    };

    React.useEffect(() => {
        getBillingList();
    }, []);

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    };

    const handleClick = (event, rows) => {
        setAnchorEl(event.currentTarget);
        setSelectedRows(rows);
        setUser(true)
    };

    const handleApproved = () => {
        setAnchorEl(null);
        setOpenPreview('Approved')
    }

    const handleEdit = () => {
        setAnchorEl(null);
        navigate("/create_quotation", { state: { data: selectedRows } })
    }
    const handleRejected = () => {
        setAnchorEl(null);
        setOpenPreview('Rejected')
    }
    const handleUpload = () => {
        setAnchorEl(null);
        setOpenDialogUpload(true)
    }
    const handleDeleted = () => {
        console.log("deleted clicked!!")
        setOpenDialog(true)
        setAnchorEl(null);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedRows("");
        setOpenDialogUpload(false)
    };

    const handleOpenForm = () => {
        setOpenForm(true)
        setAnchorEl(null);
    };

    const handleCloseForm = () => setOpenForm(false);

    const handleSubmitForm = async (formData) => {
        try {
            formData.action = 'Update Payment';
            formData.flag = 6;
            formData._id = selectedRows._id;
            formData.createdBy = selectedRows.createdBy
            const { message } = await axiosPostCall("sendInvoice", { formData });
            if (message === "Payment updated successfully!") {
                handleCloseForm();
                renderSuccessMessage(message)
                setLoadingState(false)
                setAnchorEl(null);
                getBillingList();
            } else {
                renderErrorMessage(message)
            }
        } catch (error) {
            console.log(error)
        }
    };


    const Content = () => {
        const paymentStatus = selectedRows?.payment?.map((p) => p?.paymentStatus);

        const listItems = [
            {
                condition: userRole === "superAdmin" && selectedRows.status !== "Approved" && selectedRows.status !== "Rejected",
                onClick: handleApproved,
                icon: <VerifiedIcon sx={{ color: '#1976d2', height: '17px' }} />,
                label: "Approve",
                color: "primary",
            },
            {
                condition: userRole === "superAdmin" && selectedRows.status !== "Approved" && selectedRows.status !== "Rejected",
                onClick: handleRejected,
                icon: <CancelOutlinedIcon sx={{ color: "#f44336", height: '17px' }} />,
                label: "Reject",
                color: "grey.700",
            },
            {
                condition: userRole === "superAdmin" && selectedRows.status === "Approved" && selectedRows.sendQuotation !== "sent",
                onClick: sendQuotation,
                icon: (
                    <>
                        {loadingState ? (
                            <CircularProgress size={17} sx={{ color: "#1976d2" }} />
                        ) : (
                            <SendIcon sx={{ color: "#1976d2", height: "17px" }} />
                        )}
                    </>
                ),
                label: loadingState ? "Sending..." : "Send Quotation",
                color: "primary",
            },
            {
                condition: userRole === "superAdmin" && selectedRows.status === "Approved" && selectedRows.sendQuotation === "sent" && selectedRows.sendPerformaInvoice !== "sent",
                onClick: sendInvoice,
                icon: (
                    <>
                        {loadingState ? (
                            <CircularProgress size={17} sx={{ color: "#1976d2" }} />
                        ) : (
                            <SendIcon sx={{ color: "#1976d2", height: "17px" }} />
                        )}
                    </>
                ),
                label: loadingState ? "Sending..." : "Send Proforma Invoice",
                color: "primary",
            },
            {
                condition: userRole === "superAdmin" && selectedRows.status === "Approved" && selectedRows.sendPerformaInvoice === "sent" && !paymentStatus?.includes("Completed") && !paymentStatus?.includes("Partial"),
                onClick: handleOpenForm,
                icon: <UpdateIcon sx={{ color: "#50AB64", height: '19px' }} />,
                label: "Upload Payment Details",
                color: "primary",
            },
            {
                condition: userRole === "superAdmin" && selectedRows.status === "Approved" && selectedRows.sendPerformaInvoice === "sent" && paymentStatus?.includes("Completed") && selectedRows.taxInvoice !== "uploadedTaxInvoice",
                onClick: handleUpload,
                icon: <FileUploadOutlinedIcon sx={{ color: "#1976d2", height: '19px' }} />,
                label: "Upload Tax Invoice",
                color: "primary",
            },
            {
                condition: userRole === "superAdmin" && selectedRows.status === "Approved" && selectedRows.sendPerformaInvoice === "sent" && paymentStatus?.includes("Completed") && selectedRows.taxInvoice === "uploadedTaxInvoice",
                icon: <DoneAllIcon sx={{ color: "#1976d2", height: '19px' }} />,
                label: "All actions completed",
                color: "primary",
            },
        ];

        const hideEditDelete = selectedRows.sendPerformaInvoice === "sent";

        const sharedStyles = {
            display: 'flex',
            gap: '7px',
            fontWeight: 400,
            '&:hover': {
                color: 'grey.800',
                backgroundColor: 'grey.300',
                cursor: 'pointer',
            },
        };

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}
            >
                <List>
                    {listItems.map(
                        ({ condition, onClick, icon, label, color }, index) =>
                            condition && (
                                <ListItem
                                    key={index}
                                    sx={{
                                        ...sharedStyles,
                                        color,
                                    }}
                                    onClick={onClick}
                                >
                                    {icon}
                                    {label}
                                </ListItem>
                            )
                    )}
                    {!hideEditDelete && (
                        <>
                            <ListItem
                                sx={{
                                    ...sharedStyles,
                                    color: "grey.700",
                                }}
                                onClick={handleEdit}
                            >
                                <Icon icon="lucide:edit" style={{ color: '#1976d2', height: '17px', margin: '5px' }} />
                                Edit
                            </ListItem>
                            <ListItem
                                sx={{
                                    ...sharedStyles,
                                    color: "grey.700",
                                }}
                                onClick={handleDeleted}
                            >
                                <DeleteIcon sx={{ color: "#f44336", height: '17px' }} />
                                Delete
                            </ListItem>
                        </>
                    )}
                </List>
            </Box>
        );
    };

    const ActionModals = () => (
        <>
            <CustomMenu
                anchorEl={anchorEl}
                handleClose={handleClose}
                Content={Content}
            />
        </>
    );
    const style = {
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
        padding: '8px', height: '35px'
    }

    const handleDeleteConfirm = async () => {
        try {
            setLoadingState(true)
            selectedRows.status = "Deleted";
            selectedRows.flag = 5;
            selectedRows.action = "Deleted"
            const result = await axiosPostCall("sendInvoice", { formData: selectedRows });
            if (result.message === "Invoice Deleted!") {
                setOpenDialog(false);
                getBillingList()
                renderErrorMessage(result.message)
                setLoadingState(false)
                setAnchorEl(null);
            } else {
                renderErrorMessage("Something went wrong!")
                enqueueSnackbar('Something went wrong!', {
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'error',
                });
            }
        } catch (error) {
            console.error(error)

        }
    };

    const handleDeleteCancel = () => {
        setOpenDialog(false);
    };

    const sendQuotation = async () => {
        try {
            setLoadingState(true)
            selectedRows.flag = 7;
            selectedRows.action = "Send quotation"
            const { message } = await axiosPostCall("sendInvoice", { formData: selectedRows });
            if (message === 'Quotation sent successfully') {
                getBillingList();
                renderSuccessMessage(message)
                setLoadingState(false)
                setAnchorEl(null);
            } else {
                renderErrorMessage("Something went wrong!")
            }
        } catch (error) {
            console.error(error)

        }
    }

    const sendInvoice = async () => {
        try {
            setLoadingState(true)
            selectedRows.status = "Approved";
            selectedRows.flag = 3;
            selectedRows.action = "Send PI"
            const { message } = await axiosPostCall("sendInvoice", { formData: selectedRows });
            if (message === 'Proforma Invoice sent successfully') {
                getBillingList();
                renderSuccessMessage(message)
                setLoadingState(false)
                setAnchorEl(null);
            } else {
                renderErrorMessage(message)
                setLoadingState(false)
                setAnchorEl(null)
            }
        } catch (error) {
            console.error(error)

        }
    }

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "calc(100vh - 219px)" }}>
                <Table >
                    <TableHead>
                        <TableRow>
                            {columns.map(({ id, label, align, minWidth }) => (
                                <TableCell key={id} align={align} sx={{ minWidth, padding: '8px', height: '35px' }}>
                                    {label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell
                                    align="center"
                                    colSpan={columns.length}
                                    sx={style}
                                >
                                    <CustomSkeleton variant="text" component="h6" width="40%" />
                                </TableCell>
                            </TableRow>
                        ) : rows.length > 0 ? (
                            rows.map((row, i) => {
                                const {
                                    toAddress = {},
                                    fromAddress = {},
                                    items = [],
                                    createdBy = "--",
                                    createdAt,
                                    totalDiscount = "--",
                                    totalTax = "--",
                                    netPayable,
                                    payment = "--",
                                    status,
                                    expireStatus,
                                    expireDate
                                } = row;

                                const showBadge = expireStatus === 'active'
                                const paymentStatuses = payment?.map((p) => p?.paymentStatus)
                                const chipStyles = {
                                    Rejected: {
                                        color: '#E2533F',
                                        backgroundColor: 'rgba(236, 64, 64, 0.20)',
                                    },
                                    Approved: {
                                        color: '#50AB64',
                                        backgroundColor: 'rgba(80, 171, 100, 0.20)',
                                    },
                                    Default: {
                                        color: '#D19244',
                                        backgroundColor: 'rgba(209, 146, 68, 0.20)',
                                    },
                                };

                                const { color, backgroundColor } = chipStyles[status] || chipStyles.Default;
                                const expTitle = `Expires on ${expireDate ? moment(expireDate).format("DD MMM, YYYY") : "--"}`
                                 
                                return (
                                    <TableRow hover key={`row-${i}`}>
                                        <TableCell align="start" sx={style} key={`toAddress-${i}`}>
                                            {toAddress.fullName || "--"}
                                        </TableCell>
                                        <TableCell align="center" sx={style} key={`toEmail-${i}`}>
                                            {toAddress.email || "--"}
                                        </TableCell>
                                        <TableCell align="center" sx={style} key={`fromEmail-${i}`}>
                                            {fromAddress.email || "--"}
                                        </TableCell>
                                        <TableCell align="center" sx={style} key={`items-${i}`}>
                                            {items.map((item) => item.product).join(", ") || "--"}
                                        </TableCell>
                                        <TableCell align="center" sx={style} key={`createdBy-${i}`}>
                                            {createdBy}
                                        </TableCell>
                                        <TableCell align="center" sx={style} key={`createdAt-${i}`}>
                                            {createdAt
                                                ? moment(createdAt).format("DD MMM, YYYY")
                                                : "--"}
                                        </TableCell>
                                        <TableCell align="center" sx={style} key={`discount-${i}`}>
                                            {totalDiscount}
                                        </TableCell>
                                        <TableCell align="center" sx={style} key={`tax-${i}`}>
                                            {totalTax}
                                        </TableCell>
                                        <TableCell align="center" sx={style} key={`netPayable-${i}`}>
                                            {formatCurrency(netPayable) || "--"}
                                        </TableCell>
                                        <TableCell align="center" sx={style} key={`status-${i}`}>
                                            <Chip
                                                label={status}
                                                sx={{
                                                    color,
                                                    backgroundColor,
                                                    p: "2px 0px",
                                                    height: "auto",
                                                    borderRadius: 1,
                                                    fontWeight: 500,
                                                    "&:hover": { backgroundColor },
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="center" sx={style} key={`paymentStatus-${i}`}>
                                            {paymentStatuses?.includes("Completed") ? (
                                                <Tooltip title="Completed">
                                                    <CheckCircleIcon sx={{ color: "#50AB64", height: "18px" }} />
                                                </Tooltip>
                                            ) : paymentStatuses?.includes("Failed") ? (
                                                <Tooltip title="Failed">
                                                    <CloseRoundedIcon sx={{ color: "#E2533F", height: '18px' }} />
                                                </Tooltip>
                                            ) : paymentStatuses?.includes("Partial") ? (
                                                <Tooltip title="Partial">
                                                    <HourglassBottomRoundedIcon sx={{ color: "#D19244", height: "18px" }} />
                                                </Tooltip>
                                            ) : paymentStatuses?.includes("Pending") ? (
                                                <Tooltip title="Pending">
                                                    <HourglassBottomRoundedIcon sx={{ color: "yellow" }} />
                                                </Tooltip>
                                            ) : (
                                                "--"
                                            )}
                                        </TableCell>
                                        <TableCell align="center" sx={style} key={`expireStatus-${i}`}>{expireStatus === "active" ? (<Tooltip title={expTitle}>
                                           <StyledBadge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                variant="dot"
                                                invisible={!showBadge}
                                            />
                                        </Tooltip>) : (<Tooltip title={expTitle}>
                                            <Chip
                                                label={"Expired"}
                                                sx={{
                                                    color:'#E2533F',
                                                    backgroundColor:'rgba(236, 64, 64, 0.20)',
                                                    p: "2px 0px",
                                                    height: "auto",
                                                    borderRadius: 1,
                                                    fontWeight: 500,
                                                    "&:hover": { backgroundColor:'rgba(236, 64, 64, 0.20)' },
                                                }}
                                            />
                                        </Tooltip>)}

                                        </TableCell>
                                        <TableCell align="center" sx={style} key={`actions-${i}`}>
                                            <MoreVertIcon
                                                sx={{
                                                    color: 'grey.700',
                                                    height: '18px',
                                                    width: '18px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={(e) => handleClick(e, row)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell align="center" colSpan={columns.length}>
                                    No data available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {!(rows || loading) && (
                <Box
                    sx={{
                        width: '100%',
                        height: '48vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <NetworkError
                        message={
                            !networkError
                                ? commonMessage?.noDataFound
                                : commonMessage?.networkError
                        }
                    />
                </Box>
            )}
            {open && <ActionModals />}
            {openPreview && (
                <PreviewComponent
                    open={openPreview}
                    setOpen={setOpenPreview}
                    order={selectedRows}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    userId={userId}
                />
            )}
            <Dialog open={openDialog} onClose={handleDeleteCancel}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this quotation ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="error">
                        {loadingState ? <><CircularProgress size={17} sx={{ color: "error", marginRight: '8px' }} /> Deleting... </> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal open={openForm} onClose={handleCloseForm}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 600,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6" component="h2" mb={2}>
                        Payment Details
                    </Typography>
                    <PaymentForm onSubmit={handleSubmitForm} />
                </Box>
            </Modal>
            <FileUploadDialog openDialog={openDialogUpload} handleClose={handleClose} data={selectedRows} setOpenDialogUpload={setOpenDialogUpload} />
        </Paper>
    );
}
