import './App.css';
import { createBrowserRouter, RouterProvider, createRoutesFromElements } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

// theme
import ThemeProvider from './theme';

// Routes
import AllRoutes from './lib/Routes';

//context
import { PermissionContext } from './context/PermissionContext';
import { useEffect, useState } from 'react';
import { useSetPermission } from './hooks/useSetPermission';
import { SocketProvider } from '../src/context/SocketContext';
import { axiosGetCall } from './services/apisCall';
import { useAuth } from './hooks/useAuth';
import { TokenProvider } from '../src/context/TokenContext';
import { ACCESS_KEY } from '../src/config/commonConfig';
import { renderErrorMessage } from './lib/toastMessage';


function App() {
  const [key, setKey] = useState(null);
  // Combine routes.
  const router = createBrowserRouter(createRoutesFromElements(AllRoutes(key)));
  const [isTokenFetch, setIsTokenFetch] = useState(false);
  const [tokenValue, setTokenValue] = useState(null)
  const [permission, setPermission] = useState({});
  useSetPermission(setPermission);

  const fetchtoken = async () => {
    try {
      if (!useAuth()) {
        return setIsTokenFetch(true);
      }
      const { token } = await axiosGetCall("fetchToken");
      localStorage.setItem("token", token)
      setIsTokenFetch(true);
      if (token) setTokenValue(true)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchtoken()
    const queryParams = new URLSearchParams(window.location.search);
    const keyParam = queryParams.get("key");
    if (keyParam) {
      keyParam === ACCESS_KEY ? setKey(keyParam) : renderErrorMessage("Invalid key. Please try again!");
    }
  }, [])
  
  return (
    isTokenFetch ? <HelmetProvider>
      <TokenProvider>
        <PermissionContext.Provider value={{ permission, setPermission }} >
          <ThemeProvider mode={'light'}>
            <QueryClientProvider client={queryClient}>
              <SocketProvider tokenValue={tokenValue}>
                <RouterProvider router={router} />
              </SocketProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </PermissionContext.Provider>
      </TokenProvider>
    </HelmetProvider> :
      <>
      </>
  );
}

export default App;
