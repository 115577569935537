import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { calculateShiftDuration, formatDateMDW } from 'src/utils/common';
import LogsProgressBar from './progressLoops';
import Iconify from 'src/components/iconify';
import { Chip, Popover } from '@mui/material';
import moment from 'moment';
import { useEmployee } from './useEmployee';
import { useSocket } from 'src/context/SocketContext';


function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openMore = Boolean(anchorEl);
    const id = openMore ? 'simple-popover' : undefined;

    const timeLogs = [
        { start: '00:00', end: '06:00', status: 'Sleeping', color: 'primary.main' },
        { start: '06:00', end: '12:00', status: 'Working', color: 'warning.main' },
        { start: '12:00', end: '18:00', status: 'Relaxing', color: 'error.darker' },
        { start: '18:00', end: '24:00', status: 'Sleeping', color: 'grey.100' },
    ];

    const formateLogs = (logs) => {
        const reader = {
            mainGateIn: 37,
            mainGateOut: 38
        }
        const formattedLogs = [];
        for (let i = 0; i < logs.length; i++) {
            const entry = logs[i];
            if ([reader?.mainGateIn, reader?.mainGateOut].includes(entry?.ReaderID)) {
                if (entry.IOEntryStatus === "IN") {
                    formattedLogs.push({ start: '', end: '', status: 'Office Time', color: 'success.main' })
                } else if (entry.IOEntryStatus === "OUT") {
                    formattedLogs.push({ start: '', end: '', status: 'Office Time', color: 'success.main' })
                }
            }
        }
        return []
    }
    const rnederLogs = (logs) => {

        const timeLogs1 = formateLogs(logs)
        return (
            <LogsProgressBar timeLogs={timeLogs} />
        )
    }

    const renderChip = (type) => (
        <Chip size='small' label={type === 'in' ? "In" : "Out"} color={type === 'in' ? 'success' : 'error'} />
    )

    const status = {
        IN: renderChip("in"),
        OUT: renderChip("out"),
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {formatDateMDW(row.date)}
                </TableCell>
                <TableCell align="center">{row?.EmployeeName || ""}</TableCell>
                <TableCell align="center">{row?.DepartmentName || ""}</TableCell>
                {/* <TableCell align="center">{rnederLogs(row?.history)}</TableCell> */}
                <TableCell align="center">{row?.TotalEntries || "--"}</TableCell>
                <TableCell align="center">{row?.Arrival ? moment.utc(row?.Arrival).format('hh:mm:ss A') : "--"}</TableCell>
                <TableCell align="center">{row?.OutTime ? moment.utc(row?.OutTime).format('hh:mm:ss A') : "--"}</TableCell>
                {/* <TableCell align="center">{row?.Difference || "--"}</TableCell> */}
                {/* <TableCell align="center">{calculateShiftDuration(row.EffectiveHours, row.GrossHours)}</TableCell> */}
                <TableCell align="center">{row?.FloorTime || "--"}</TableCell>
                <TableCell align="center">{row?.OutSideFloorTime || "--"}</TableCell>
                <TableCell align="center">{row?.OutSideOfficeTime || "--"}</TableCell>
                <TableCell align="center">{row?.Difference || "--"}</TableCell>
                <TableCell align="center">{row?.TotalTime || "--"}</TableCell>
                <TableCell align="center">
                    <IconButton aria-describedby={id} onClick={handleClick}>
                        <Iconify icon="ant-design:more-outlined" width="1024" height="1024" />
                    </IconButton>

                    <Popover
                        id={id}
                        open={openMore}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        sx={{
                            p: '10px'
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            alignItems: 'center',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                alignItems: 'center',
                                cursor: 'pointer',
                                "&:hover": {
                                    backgroundColor: 'grey.200'
                                },
                                padding: '6px',
                                borderRadius: '6px'
                            }}>
                                <Iconify icon="majesticons:mail-line" width="1024" height="1024" />
                                <Typography>
                                    Send Policy Reminder
                                </Typography>
                            </Box>
                        </Box>

                    </Popover>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>S. NO.</TableCell>
                                        <TableCell>Reader Name </TableCell>
                                        <TableCell>Reader ID</TableCell>
                                        <TableCell>Time</TableCell>
                                        <TableCell>Action</TableCell>
                                        <TableCell align="center">Difference Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.history.map((historyRow, i) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>{historyRow?.ReaderName}</TableCell>
                                            <TableCell>{historyRow?.ReaderID}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {moment.utc(historyRow.aDateTime).format('hh:mm:ss A')}
                                            </TableCell>
                                            {/* <TableCell>{status.in}</TableCell> */}
                                            <TableCell>{status[historyRow?.IOEntryStatus] || "--"}</TableCell>
                                            <TableCell align="center">{historyRow.amount}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

export default function EmpListTable({ filters }) {
    const ws = useSocket()
    const { list, fetchLogs, listLoading } = useEmployee({ ws });
    console.log(list, listLoading)

    React.useEffect(() => {
        fetchLogs(filters)
    }, [filters])

    return (
        <TableContainer component={Paper}>
            <Table stickyHeader aria-label="employee table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Date</TableCell>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center">Department</TableCell>
                        <TableCell align="center">Total Entries</TableCell>
                        <TableCell align="center">Arrival</TableCell>
                        <TableCell align="center">Out Time</TableCell>
                        {/* <TableCell align="center">Effective Hours</TableCell> */}
                        {/* <TableCell align="center">Gross Hours</TableCell> */}
                        <TableCell align="center">Floor Time</TableCell>
                        <TableCell align="center">Out Side Floor </TableCell>
                        <TableCell align="center">Out Side Office Time</TableCell>
                        <TableCell align="center">Gross Time</TableCell>
                        <TableCell align="center">Total Time</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((row) => (
                        <Row key={row.name} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
