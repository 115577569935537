import React, { useState, useEffect, useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import io from 'socket.io-client';  // Import socket.io client
import { useSocket } from 'src/context/SocketContext';
import { useNavigate } from "react-router-dom";
import { axiosPostCall } from 'src/services/apisCall';
import { getItem } from 'src/lib/cookies';
// import { jwtDecode } from 'jwt-decode';
import { Card, CardContent, Typography, IconButton, Stack } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CloseIcon from '@mui/icons-material/Close';
import {TokenContext} from '.././../context/TokenContext'
 
const NotificationPopup = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const socket = useSocket();
    const navigate = useNavigate();
    const [amEmail, setAmEmail] = useState("");
    const [userId, setUserId] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const {decodedToken} = useContext(TokenContext);
    // Initialize WebSocket connection
    useEffect(() => {

        if (Notification.permission === 'default') {
            Notification.requestPermission();
        }
        
        if(decodedToken){
            const { email = "" } = decodedToken;
            setAmEmail(email);
        }

        socket.on('am_assign_request', (data) => {
            setUserId(data?.userId)
            setUserEmail(data?.email);
            if (document.hidden) {
                if (Notification.permission === 'granted') {
                    const notification = new Notification('New Assignment Request', {
                        body: `You have a new request from ${userEmail}`,
                    });
                    handleShowNotification(data);
                    // Handle notification click to redirect to a specific page
                    notification.onclick = () => {
                        window.focus();
                        navigate("/home", { state: { data } });  // Redirect to page with data
                    };
                }
            } else {
                handleShowNotification(data);
            }

        });

        return () => {
            socket.disconnect();
        };
    }, [userId]);

    const handleShowNotification = (data) => {
        // if (document.visibilityState === 'visible') {
        setMessage(data.message);  // Set message from data
        setOpen(true);  // Show the notification
        // }
    };

    // Function to close the notification
    const handleClose = () => {
        setOpen(false);
    };

    // Example handler for "Pass" action
    const handlePass = async () => {
        const data = {
            userId,
            userEmail,
            amId: amEmail,
            response: "pass",
        }
        await axiosPostCall("assignAMpass", data)
        handleClose();  // Close the notification
    };

    // Example handler for "Accept" action
    const handleAccept = async () => {
        const data = {
            userId,
            amId: amEmail,
            userEmail,
            response: "accept",
        }
        await axiosPostCall("assignAMpass", data)
        handleClose();  // Close the notification
    };

    return (
        <div>
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleClose}
                autoHideDuration={null}
            >
                <Card
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#f5f5f5',
                        border: '1px solid #ccc',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        width: '350px',
                    }}
                >
                    <CardContent sx={{display: "flex", flexDirection: "column", alignItems: 'center', gap: "10px" }}>
                        <Stack direction={"row"}>
                            <AssignmentIcon style={{ fontSize: '40px', color: '#4caf50', marginRight: '10px' }} />
                            <div style={{ flexGrow: 1 }}>
                                <Typography variant="h6" component="div">
                                    New Assignment Request
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    You have a new request from {userEmail}
                                </Typography>
                            </div>
                        </Stack>
                        <Stack direction={"row"} gap={2} justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleAccept}
                                style={{ marginRight: '5px' }}
                            >
                                Accept
                            </Button>
                            <Button variant="contained" color="secondary" size="small" onClick={handlePass}>
                                Pass
                            </Button>
                        </Stack>
                        {/* <IconButton size="small" onClick={handleClose} style={{ marginLeft: '10px' }}>
                            <CloseIcon />
                        </IconButton> */}
                    </CardContent>
                </Card>
            </Snackbar>
        </div>
    );
};

export default NotificationPopup;
