import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
export const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token')); 
    const [decodedToken, setDecodedToken] = useState(null); 

    useEffect(() => {
        if (token) {
            try {
                const decoded = jwtDecode(token);
                setDecodedToken(decoded); 
            } catch (error) {
                console.error('JWT Decode Error:', error);
                setDecodedToken(null);
            }
        } else {
            setDecodedToken(null);
        }
    }, [token]); 

    const updateToken = (newToken) => {
        setToken(newToken);
        if (newToken) {
            localStorage.setItem('token', newToken); 
        }
    };
    
    return (
        <TokenContext.Provider value={{ token, decodedToken, setToken: updateToken }}>
            {children}
        </TokenContext.Provider>
    );
};
