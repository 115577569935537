const USER_KYC_LIST_REFETCH_TIME = 600000;
const LOGIN_PAGE_RELOAD_TIME_ON_ERROR = 5000;
const NON_SECURE_PATH = ["/profile"]

const allPermissionsRows = {
    kycRow: [
        {
            name: "Can view Kyc page and list",
            mapKey: "kycPageAndList"
        },
        {
            name: "Add kyc",
            mapKey: "addKyc"
        },
        {
            name: "Verify kyc",
            mapKey: "verifyKyc"
        }
    ],
    consoleRow: [
        {
            name: "Can view Console page and list",
            mapKey: "consolePageAndList"
        },
        {
            name: "Ref Login",
            mapKey: "refLogin"
        }
    ],
    adminRow: [
        {
            name: "Can view Admin page and list",
            mapKey: "adminPageAndList"
        },
        {
            name: "Add New User",
            mapKey: "addNewUser"
        },
        {
            name: "Leads",
            mapKey: "leads"
        }
    ],
    advertisementRow: [
        {
            name: "Can view advertisement page and list",
            mapKey: "advertisementPageAndList"
        },
        {
            name: "Upload Advertisement",
            mapKey: "uploadAdvertisement"
        }
    ],
    homeRow: [
        {
            name: "Can view Home page and list",
            mapKey: "homePageAndList"
        }
    ],
    meetRow: [
        {
            name: "Can view Meet page and list",
            mapKey: "meetPageAndList"
        }
    ],
    webinarRow: [
        {
            name: "Can view Webinar page and list",
            mapKey: "webinarPageAndList"
        }
    ],
    smsRow: [
        {
            name: "Can view Sms page and list",
            mapKey: "smsPageAndList"
        }
    ]
}

const ACCESS_KEY = "ff1dd0e4cb87a3c89e03763030b033ab";


export { USER_KYC_LIST_REFETCH_TIME, LOGIN_PAGE_RELOAD_TIME_ON_ERROR, NON_SECURE_PATH, allPermissionsRows, ACCESS_KEY};
