
// eslint-disable-next-line
const Attachment45LineIcon = ({ color }) => {
    return (
        <svg viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.98292 5.58745L5.68306 9.12297C5.45525 9.36703 5.45525 9.76278 5.68306 10.0068C5.91086 10.251 6.28022 10.251 6.50801 10.0068L9.80787 6.47133C10.4912 5.7391 10.4912 4.55192 9.80787 3.81968C9.12444 3.08745 8.01639 3.08745 7.33296 3.81968L4.03314 7.35522C2.89411 8.57559 2.89411 10.5542 4.03314 11.7747C5.17217 12.995 7.01889 12.995 8.15791 11.7747L11.4578 8.23909L12.2827 9.12297L8.98292 12.6585C7.38826 14.367 4.80283 14.367 3.20819 12.6585C1.61355 10.95 1.61355 8.1799 3.20819 6.47133L6.50801 2.9358C7.64703 1.71541 9.4938 1.71541 10.6328 2.9358C11.7718 4.15619 11.7718 6.13482 10.6328 7.35522L7.33296 10.8908C6.64959 11.623 5.54152 11.623 4.8581 10.8908C4.17468 10.1585 4.17468 8.97134 4.8581 8.23909L8.15791 4.70357L8.98292 5.58745Z" fill={color} />
        </svg>

    )
}

export default Attachment45LineIcon