import PropTypes from 'prop-types';
import { Box, Typography, IconButton, Grid } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

const ComposeAttachments = ({ fileName, size, id, removeAttachment }) => {

    const formatFileSize = (size) => {
        if (size < 1024) return `${size} bytes`;
        if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
        return `${(size / (1024 * 1024)).toFixed(1)} MB`;
    };
    return (
        <Grid container spacing={2} sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'grey.200',
            margin: '0px 1px', //comment
            maxWidth: '90%',
            borderRadius: '2px'
        }}>
            <Grid item xs={11} sx={{ padding: '0px 15px', paddingTop: '0px!important' }}>
                <Typography sx={{ color: 'primary.main', fontSize: '14px', fontWeight: 600 }}>
                    {fileName} <Box component={'span'} sx={{ color: "grey.600" }}>
                        {`(${formatFileSize(size)})`}
                    </Box>
                </Typography>
            </Grid>


            <IconButton sx={{ width: '30px', height: '30px' }} onClick={() => removeAttachment(id)}>
                <CloseIcon sx={{ width: '16px', height: '16px' }} />
            </IconButton>
        </Grid>
    )
}

export default ComposeAttachments;

ComposeAttachments.propTypes = {
    fileName: PropTypes.string,
    size: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    removeAttachment: PropTypes.func,
}